import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDirectivesModule } from './common-directives/common-directives.module';
import { CommonPipesModule } from './common-pipes/common-pipes.module';
import { MaterialModule } from './material/material.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonPipesModule,
    TranslateModule,
    MaterialModule,
    CommonDirectivesModule,
    RouterModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonPipesModule,
    TranslateModule,
    MaterialModule,
    CommonDirectivesModule,
    RouterModule,
  ]
})
export class CommonShareModule {

}
