export enum TaskStatesEnum {

  AwaitingDecision = 'AWAITING_DECISION',

  AcceptByFreelancer = 'ACCEPT_BY_FREELANCER',

  AcceptByOwner = 'ACCEPT_BY_OWNER',

  DeclineByFreelancer = 'DECLINE_BY_FREELANCER',

  DeclineByOwner = 'DECLINE_BY_OWNER',

  InDispute = 'IN_DISPUTE',

  Arbitrage = 'ARBITRAGE',

  ArbitrageDecisionWasMade = 'ARBITRAGE_DECISION_WAS_MADE',
}
