<tpt-label>
  <ng-content select="[label]"></ng-content>
</tpt-label>

<div class="autocomplete">
  <input matInput type="text" class="search"
         [class.-error-state]="errorState"
         [matAutocomplete]="auto"
         [placeholder]="placeholder" spellcheck="false" [formControl]="addressFormControl" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of data" [value]="option.value">
      {{option.value}}
    </mat-option>
  </mat-autocomplete>

  <mat-error [class.invisible]="!errorState">
    {{ error | translate}}
  </mat-error>
</div>
