import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'challenge-decision-dialog',
  templateUrl: './challenge-decision-dialog.component.html',
  styleUrls: ['./challenge-decision-dialog.component.scss']
})
export class ChallengeDecisionDialogComponent  {

  @Output()
  public readonly challengeDecisionEvent = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public reason = '';

  private className = 'challenge-decision-dialog';

  private config: MatDialogConfig = {
    width: '460px',
  };

  constructor(private profile: ProfileService) {
  }

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public challengeDecision(): void {
    if (!this.reason.trim()) { return; }
    this.challengeDecisionEvent.emit(this.reason);
    this.reason = '';
    this.close();
  }
}
