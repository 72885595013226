import { Component, Input, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { FinanceServiceV2 } from '../../services/finance.service';

@Component({
  selector: 'tpt-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: [ './success-dialog.component.scss' ],
})
export class SuccessDialogComponent {
  @Input() closeAndUpdateFinanceInfo: boolean;

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  private className = 'tpt-success-dialog';

  constructor(private financeServiceV2: FinanceServiceV2) {
  }

  public open(): void {
    this.dialog.open(this.className);
  }

  public close(): void {
    if (this.closeAndUpdateFinanceInfo) {
      this.financeServiceV2.subject.next(null);
    }
    this.dialog.close();
  }

}
