import { Pipe, PipeTransform } from '@angular/core';
import { ExpertLevel } from '../../../classes/enums';

@Pipe({
  name: 'tptExpertLevels'
})
export class ExpertLevelsPipe implements PipeTransform {

  transform(levels: ExpertLevel[], ...args: any[]): string[] {
    return levels ? levels.map(this.mapLevelToReadableText) : [];
  }

  private mapLevelToReadableText = (level: ExpertLevel): string => {
    switch (level) {
      case ExpertLevel.BEGINNER:
        return 'Начинающий';
      case ExpertLevel.INTERMEDIATE:
        return 'Средний';
      case ExpertLevel.MASTER:
        return 'Эксперт';
    }
  }

}
