import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-failure-dialog',
  templateUrl: './failure-dialog.component.html',
  styleUrls: [ './failure-dialog.component.scss' ],
})
export class FailureDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  private className = 'tpt-failure-dialog';

  public open(): void {
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
