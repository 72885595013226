<div class="footer">
  <div class="footer-container">
    <div class="top-block">
      <div class="first-column">
        <div>
          <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo"></mat-icon>
          <div class="logo-text">Платформа для работы с самозанятыми</div>
        </div>

      </div>

      <div class="footer-grid">

        <div class="help">
          <div class="subtitle">Самозанятым</div>
          <div class="point"><a href="https://npd.nalog.ru/faq/" target="_blank">База знаний ФНС</a></div>
          <div class="point"><a href="https://npd.nalog.ru/" target="_blank">Сайт “Мой налог”</a></div>
          <div class="point"><a href="https://lknpd.nalog.ru/" target="_blank">Личный кабинет самозанятого</a></div>
          <div class="point"><a href="https://npd.nalog.ru/#start " target="_blank">Как стать самозанятым</a></div>
        </div>

        <div class="legal">
          <div class="subtitle">Юридическая информация</div>
          <div class="point"><a href="/legal/terms">Пользовательское соглашение</a></div>
          <div class="point"><a href="/legal/privacy">Политика конфиденциальности</a></div>
        </div>

        <div class="nalog-app">
          <div class="subtitle">Мобильное приложение <br/> “Мой налог”
            <img src="assets/images/fns.png"
                 style="width: 25px; height: 25px; margin-left: 4px; vertical-align: middle">
          </div>
          <div class="badges">
            <a class="google"
               target="_blank"
               href='https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=ru&gl=US'>
              <img
                alt='Доступно в Google Play'
                src="assets/images/google-badge.png"/>
            </a>
            <a
              href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854?l=en"
              target="_blank" class="apple">
              <img
                src="assets/images/apple-badge.png"
                alt="Загрузите в App Store"/></a>
          </div>

        </div>
      </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="bottom-info">
      <div class="text">© {{year}} Remote Staffing Platform Ltd.</div>
      <div class="support">
            <span class="mail-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.332 2.66699H2.66536C1.93203 2.66699 1.3387 3.26699 1.3387 4.00033L1.33203 12.0003C1.33203 12.7337 1.93203 13.3337 2.66536 13.3337H13.332C14.0654 13.3337 14.6654 12.7337 14.6654 12.0003V4.00033C14.6654 3.26699 14.0654 2.66699 13.332 2.66699ZM13.332 12.0003H2.66536V5.33366L7.9987 8.66699L13.332 5.33366V12.0003ZM7.9987 7.33366L2.66536 4.00033H13.332L7.9987 7.33366Z"
                fill="#626373"/>
              </svg>
            </span>
        <div><a href="mailto:info@tp.team">info@tp.team</a></div>
      </div>
    </div>
  </div>
</div>
