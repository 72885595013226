import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'smth-went-wrong',
  templateUrl: './smth-went-wrong.component.html',
  styleUrls: ['./smth-went-wrong.component.scss']
})
export class SmthWentWrongComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  private className = 'smth-went-wrong';

  public open(): void {
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
