import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TptControlDirective } from '../../directives/tpt-control.directive';

@Component({
  selector: 'tpt-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
})
export class InputComponent extends TptControlDirective {

  @Output()
  public keyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Output()
  public onFocus = new EventEmitter();

  @Input()
  public uppercase = false;

  @Input()
  public showLabel = true;

  @Input()
  public mask = '';

  @Input()
  public disabled = false;

  @Input()
  public type: 'text' | 'number' | 'range' | 'password' | string;

  @HostBinding('class.tpt-input')
  public baseClass = true;

}
