import { Injectable } from '@angular/core';

import { Moment } from 'moment-timezone';
import { ITimezone } from '../classes/iTimezone';
import { moment } from '../moment-helper';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private DEFAULT_ZONE_ID = 'UTC';
  private _currentZone: string;
  get currentZone(): string {
    if (!this._currentZone) {
      this._currentZone = this.iZoneById(moment.tz.guess()).zoneId;
    }
    return this._currentZone;
  }

  public iZoneById(zoneId: string): ITimezone {
    const zone = this.getZones().find((zone) => !!zone.zones.find((z) => z === zoneId));
    return zone || this.getDefaultZone();
  }

  public getMinDate(zone: string, operationTime: Moment, otherDate?: Date): Date {
    const currentDate = moment().tz(zone);
    const dateAtOperationTime: Moment = currentDate.clone();
    dateAtOperationTime
      .hour(operationTime.hour())
      .minute(operationTime.minute())
      .second(0);

    if (currentDate.isAfter(dateAtOperationTime)) {
      currentDate.add({days: 1});
    }

    if (otherDate) {
      const momentOtherDate = moment(otherDate);
      if (dateAtOperationTime.isBefore(moment(otherDate))) {
        return this.toDate(momentOtherDate);
      }
    }
    return this.toDate(currentDate);
  }

  public toDateWithZone(date: Date | Moment, zone: string): Date {
    return this.toDate(moment(date).tz(zone));
  }

  /**
   * Get the timezones for each country
   */
  public getZones(): ITimezone[] {
    return zones;
  }

  public getDefaultZone(): ITimezone {
    return this.iZoneById(this.DEFAULT_ZONE_ID);
  }

  public convertOperationTime(operationTime: string): Moment {
    return moment(operationTime, ['h:m a', 'H:m']);
  }

  private toDate(date: Moment): Date {
    return new Date(date.format('YYYY-MM-DD'));
  }

}

const zones: ITimezone[] = [
  {
    zoneId: 'Pacific/Niue',
    label: '(UTC-11:00) Niue',
    zones: ['Pacific/Niue']
  },
  {
    zoneId: 'Pacific/Pago_Pago',
    label: '(UTC-11:00) Pago Pago, Midway',
    zones: ['Pacific/Pago_Pago', 'Pacific/Midway']
  },
  {
    zoneId: 'Pacific/Rarotonga',
    label: '(UTC-10:00) Rarotonga',
    zones: ['Pacific/Rarotonga']
  },
  {
    zoneId: 'Pacific/Tahiti',
    label: '(UTC-10:00) Tahiti',
    zones: ['Pacific/Tahiti']
  },
  {
    zoneId: 'Pacific/Honolulu',
    label: '(UTC-10:00) Honolulu, Adak',
    zones: ['Pacific/Honolulu', 'America/Adak']
  },
  {
    zoneId: 'HST',
    label: '(UTC-10:00) Hawaii Standard Time',
    zones: ['HST']
  },
  {
    zoneId: 'Pacific/Marquesas',
    label: '(UTC-09:30) Marquesas',
    zones: ['Pacific/Marquesas']
  },
  {
    zoneId: 'America/Juneau',
    label: '(UTC-09:00) Juneau, Metlakatla, Yakutat, Sitka, Anchorage, Nome',
    zones: ['America/Juneau',
            'America/Metlakatla',
            'America/Yakutat',
            'America/Sitka',
            'America/Anchorage',
            'America/Nome']
  },
  {
    zoneId: 'Pacific/Gambier',
    label: '(UTC-09:00) Gambier',
    zones: ['Pacific/Gambier']
  },
  {
    zoneId: 'Pacific/Pitcairn',
    label: '(UTC-08:00) Pitcairn',
    zones: ['Pacific/Pitcairn']
  },
  {
    zoneId: 'America/Tijuana',
    label: '(UTC-08:00) Tijuana',
    zones: ['America/Tijuana']
  },
  {
    zoneId: 'America/Los_Angeles',
    label: '(UTC-08:00) Los Angeles',
    zones: ['America/Los_Angeles']
  },
  {
    zoneId: 'America/Dawson',
    label: '(UTC-08:00) Dawson, Vancouver, Whitehorse',
    zones: ['America/Dawson', 'America/Vancouver', 'America/Whitehorse']
  },
  {
    zoneId: 'PST8PDT',
    label: '(UTC-08:00) Pacific Standard Time',
    zones: ['PST8PDT']
  },
  {
    zoneId: 'America/Mazatlan',
    label: '(UTC-07:00) Mazatlan, Chihuahua, Ojinaga, Hermosillo',
    zones: ['America/Mazatlan', 'America/Chihuahua', 'America/Ojinaga', 'America/Hermosillo']
  },
  {
    zoneId: 'America/Dawson_Creek',
    label: '(UTC-07:00) Dawson Creek, Yellowknife, Inuvik, Creston, Edmonton, Cambridge Bay, Fort Nelson',
    zones: ['America/Dawson_Creek',
            'America/Yellowknife',
            'America/Inuvik',
            'America/Creston',
            'America/Edmonton',
            'America/Cambridge_Bay',
            'America/Fort_Nelson']
  },
  {
    zoneId: 'MST7MDT',
    label: '(UTC-07:00) Mountain Standard Time, Mountain Standard Time',
    zones: ['MST7MDT', 'MST']
  },
  {
    zoneId: 'America/Denver',
    label: '(UTC-07:00) Denver, Boise, Phoenix',
    zones: ['America/Denver', 'America/Boise', 'America/Phoenix']
  },
  {
    zoneId: 'America/Chicago',
    label: '(UTC-06:00) Chicago, Knox, Beulah, Center, Tell City, Menominee, New Salem',
    zones: ['America/Chicago',
            'America/Indiana/Knox',
            'America/North_Dakota/Beulah',
            'America/North_Dakota/Center',
            'America/Indiana/Tell_City',
            'America/Menominee',
            'America/North_Dakota/New_Salem']
  },
  {
    zoneId: 'America/Tegucigalpa',
    label: '(UTC-06:00) Tegucigalpa',
    zones: ['America/Tegucigalpa']
  },
  {
    zoneId: 'America/Monterrey',
    label: '(UTC-06:00) Monterrey, Mexico City, Matamoros, Bahia Banderas, Merida',
    zones: ['America/Monterrey', 'America/Mexico_City', 'America/Matamoros', 'America/Bahia_Banderas', 'America/Merida']
  },
  {
    zoneId: 'America/Managua',
    label: '(UTC-06:00) Managua',
    zones: ['America/Managua']
  },
  {
    zoneId: 'America/Regina',
    label: '(UTC-06:00) Regina, Rankin Inlet, Rainy River, Swift Current, Resolute, Winnipeg',
    zones: ['America/Regina',
            'America/Rankin_Inlet',
            'America/Rainy_River',
            'America/Swift_Current',
            'America/Resolute',
            'America/Winnipeg']
  },
  {
    zoneId: 'America/El_Salvador',
    label: '(UTC-06:00) El Salvador',
    zones: ['America/El_Salvador']
  },
  {
    zoneId: 'America/Costa_Rica',
    label: '(UTC-06:00) Costa Rica',
    zones: ['America/Costa_Rica']
  },
  {
    zoneId: 'CST6CDT',
    label: '(UTC-06:00) Central Standard Time',
    zones: ['CST6CDT']
  },
  {
    zoneId: 'America/Belize',
    label: '(UTC-06:00) Belize',
    zones: ['America/Belize']
  },
  {
    zoneId: 'America/Guatemala',
    label: '(UTC-06:00) Guatemala',
    zones: ['America/Guatemala']
  },
  {
    zoneId: 'Pacific/Galapagos',
    label: '(UTC-06:00) Galapagos',
    zones: ['Pacific/Galapagos']
  },
  {
    zoneId: 'America/Havana',
    label: '(UTC-05:00) Havana',
    zones: ['America/Havana']
  },
  {
    zoneId: 'America/Panama',
    label: '(UTC-05:00) Panama, Cayman',
    zones: ['America/Panama', 'America/Cayman']
  },
  {
    zoneId: 'America/Port-au-Prince',
    label: '(UTC-05:00) Port-au-Prince',
    zones: ['America/Port-au-Prince']
  },
  {
    zoneId: 'America/Nassau',
    label: '(UTC-05:00) Nassau',
    zones: ['America/Nassau']
  },
  {
    zoneId: 'America/Guayaquil',
    label: '(UTC-05:00) Guayaquil',
    zones: ['America/Guayaquil']
  },
  {
    zoneId: 'America/Eirunepe',
    label: '(UTC-05:00) Eirunepe, Rio Branco',
    zones: ['America/Eirunepe', 'America/Rio_Branco']
  },
  {
    zoneId: 'America/Indiana/Petersburg',
    label: '(UTC-05:00) Petersburg, Vevay, Vincennes, Louisville, Monticello, Indianapolis, Marengo, Detroit, Winamac, New York',
    zones: ['America/Indiana/Petersburg',
            'America/Indiana/Vevay',
            'America/Indiana/Vincennes',
            'America/Kentucky/Louisville',
            'America/Kentucky/Monticello',
            'America/Indiana/Indianapolis',
            'America/Indiana/Marengo',
            'America/Detroit',
            'America/Indiana/Winamac',
            'America/New_York']
  },
  {
    zoneId: 'America/Bogota',
    label: '(UTC-05:00) Bogota',
    zones: ['America/Bogota']
  },
  {
    zoneId: 'America/Iqaluit',
    label: '(UTC-05:00) Iqaluit, Atikokan, Thunder Bay, Nipigon, Pangnirtung, Toronto',
    zones: ['America/Iqaluit',
            'America/Atikokan',
            'America/Thunder_Bay',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Toronto']
  },
  {
    zoneId: 'America/Lima',
    label: '(UTC-05:00) Lima',
    zones: ['America/Lima']
  },
  {
    zoneId: 'America/Grand_Turk',
    label: '(UTC-05:00) Grand Turk',
    zones: ['America/Grand_Turk']
  },
  {
    zoneId: 'America/Cancun',
    label: '(UTC-05:00) Cancun',
    zones: ['America/Cancun']
  },
  {
    zoneId: 'EST',
    label: '(UTC-05:00) Eastern Standard Time, Eastern Standard Time',
    zones: ['EST', 'EST5EDT']
  },
  {
    zoneId: 'America/Jamaica',
    label: '(UTC-05:00) Jamaica',
    zones: ['America/Jamaica']
  },
  {
    zoneId: 'Pacific/Easter',
    label: '(UTC-05:00) Easter',
    zones: ['Pacific/Easter']
  },
  {
    zoneId: 'America/Guyana',
    label: '(UTC-04:00) Guyana',
    zones: ['America/Guyana']
  },
  {
    zoneId: 'America/Marigot',
    label: '(UTC-04:00) Marigot, St Thomas, Anguilla, Port of Spain, Antigua, St Vincent, Dominica, St Barthelemy, St Lucia, Montserrat, Tortola, Grenada, St Kitts, Guadeloupe',
    zones: ['America/Marigot',
            'America/St_Thomas',
            'America/Anguilla',
            'America/Port_of_Spain',
            'America/Antigua',
            'America/St_Vincent',
            'America/Dominica',
            'America/St_Barthelemy',
            'America/St_Lucia',
            'America/Montserrat',
            'America/Tortola',
            'America/Grenada',
            'America/St_Kitts',
            'America/Guadeloupe']
  },
  {
    zoneId: 'Atlantic/Bermuda',
    label: '(UTC-04:00) Bermuda',
    zones: ['Atlantic/Bermuda']
  },
  {
    zoneId: 'America/Cuiaba',
    label: '(UTC-04:00) Cuiaba, Manaus, Porto Velho, Campo Grande, Boa Vista',
    zones: ['America/Cuiaba', 'America/Manaus', 'America/Porto_Velho', 'America/Campo_Grande', 'America/Boa_Vista']
  },
  {
    zoneId: 'America/Caracas',
    label: '(UTC-04:00) Caracas',
    zones: ['America/Caracas']
  },
  {
    zoneId: 'America/Barbados',
    label: '(UTC-04:00) Barbados',
    zones: ['America/Barbados']
  },
  {
    zoneId: 'America/La_Paz',
    label: '(UTC-04:00) La Paz',
    zones: ['America/La_Paz']
  },
  {
    zoneId: 'America/Curacao',
    label: '(UTC-04:00) Curacao, Kralendijk, Aruba, Lower Princes',
    zones: ['America/Curacao', 'America/Kralendijk', 'America/Aruba', 'America/Lower_Princes']
  },
  {
    zoneId: 'America/Moncton',
    label: '(UTC-04:00) Moncton, Halifax, Blanc-Sablon, Glace Bay, Goose Bay',
    zones: ['America/Moncton', 'America/Halifax', 'America/Blanc-Sablon', 'America/Glace_Bay', 'America/Goose_Bay']
  },
  {
    zoneId: 'America/Thule',
    label: '(UTC-04:00) Thule',
    zones: ['America/Thule']
  },
  {
    zoneId: 'America/Martinique',
    label: '(UTC-04:00) Martinique',
    zones: ['America/Martinique']
  },
  {
    zoneId: 'America/Puerto_Rico',
    label: '(UTC-04:00) Puerto Rico',
    zones: ['America/Puerto_Rico']
  },
  {
    zoneId: 'America/Santo_Domingo',
    label: '(UTC-04:00) Santo Domingo',
    zones: ['America/Santo_Domingo']
  },
  {
    zoneId: 'America/St_Johns',
    label: '(UTC-03:30) St Johns',
    zones: ['America/St_Johns']
  },
  {
    zoneId: 'America/Asuncion',
    label: '(UTC-03:00) Asuncion',
    zones: ['America/Asuncion']
  },
  {
    zoneId: 'America/Santiago',
    label: '(UTC-03:00) Santiago',
    zones: ['America/Santiago']
  },
  {
    zoneId: 'America/Cayenne',
    label: '(UTC-03:00) Cayenne',
    zones: ['America/Cayenne']
  },
  {
    zoneId: 'Atlantic/Stanley',
    label: '(UTC-03:00) Stanley',
    zones: ['Atlantic/Stanley']
  },
  {
    zoneId: 'America/Miquelon',
    label: '(UTC-03:00) Miquelon',
    zones: ['America/Miquelon']
  },
  {
    zoneId: 'Antarctica/Rothera',
    label: '(UTC-03:00) Rothera',
    zones: ['Antarctica/Rothera']
  },
  {
    zoneId: 'America/Godthab',
    label: '(UTC-03:00) Godthab',
    zones: ['America/Godthab']
  },
  {
    zoneId: 'America/Montevideo',
    label: '(UTC-03:00) Montevideo',
    zones: ['America/Montevideo']
  },
  {
    zoneId: 'America/Paramaribo',
    label: '(UTC-03:00) Paramaribo',
    zones: ['America/Paramaribo']
  },
  {
    zoneId: 'America/Argentina/Catamarca',
    label: '(UTC-03:00) Catamarca, Cordoba, Salta, Mendoza, Rio Gallegos, Jujuy, San Luis, Ushuaia, La Rioja, San Juan, Tucuman, Buenos Aires',
    zones: ['America/Argentina/Catamarca',
            'America/Argentina/Cordoba',
            'America/Argentina/Salta',
            'America/Argentina/Mendoza',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Jujuy',
            'America/Argentina/San_Luis',
            'America/Argentina/Ushuaia',
            'America/Argentina/La_Rioja',
            'America/Argentina/San_Juan',
            'America/Argentina/Tucuman',
            'America/Argentina/Buenos_Aires']
  },
  {
    zoneId: 'Antarctica/Palmer',
    label: '(UTC-03:00) Palmer',
    zones: ['Antarctica/Palmer']
  },
  {
    zoneId: 'America/Punta_Arenas',
    label: '(UTC-03:00) Punta Arenas',
    zones: ['America/Punta_Arenas']
  },
  {
    zoneId: 'America/Araguaina',
    label: '(UTC-03:00) Araguaina, Sao Paulo, Recife, Santarem, Maceio, Fortaleza, Belem, Bahia',
    zones: ['America/Araguaina',
            'America/Sao_Paulo',
            'America/Recife',
            'America/Santarem',
            'America/Maceio',
            'America/Fortaleza',
            'America/Belem',
            'America/Bahia']
  },
  {
    zoneId: 'America/Noronha',
    label: '(UTC-02:00) Noronha',
    zones: ['America/Noronha']
  },
  {
    zoneId: 'Atlantic/South_Georgia',
    label: '(UTC-02:00) South Georgia',
    zones: ['Atlantic/South_Georgia']
  },
  {
    zoneId: 'Atlantic/Azores',
    label: '(UTC-01:00) Azores',
    zones: ['Atlantic/Azores']
  },
  {
    zoneId: 'America/Scoresbysund',
    label: '(UTC-01:00) Scoresbysund',
    zones: ['America/Scoresbysund']
  },
  {
    zoneId: 'Atlantic/Cape_Verde',
    label: '(UTC-01:00) Cape Verde',
    zones: ['Atlantic/Cape_Verde']
  },
  {
    zoneId: 'UTC',
    label: '(UTC+00:00) Coordinated Universal Time, UCT, UTC',
    zones: ['UTC', 'Etc/UCT', 'Etc/UTC']
  },
  {
    zoneId: 'Atlantic/Faroe',
    label: '(UTC+00:00) Faroe',
    zones: ['Atlantic/Faroe']
  },
  {
    zoneId: 'Europe/London',
    label: '(UTC+00:00) London, Jersey, Guernsey, Isle of Man',
    zones: ['Europe/London', 'Europe/Jersey', 'Europe/Guernsey', 'Europe/Isle_of_Man']
  },
  {
    zoneId: 'America/Danmarkshavn',
    label: '(UTC+00:00) Danmarkshavn',
    zones: ['America/Danmarkshavn']
  },
  {
    zoneId: 'Atlantic/Madeira',
    label: '(UTC+00:00) Madeira, Lisbon',
    zones: ['Atlantic/Madeira', 'Europe/Lisbon']
  },
  {
    zoneId: 'Africa/Monrovia',
    label: '(UTC+00:00) Monrovia',
    zones: ['Africa/Monrovia']
  },
  {
    zoneId: 'Europe/Dublin',
    label: '(UTC+00:00) Dublin',
    zones: ['Europe/Dublin']
  },
  {
    zoneId: 'Africa/Bissau',
    label: '(UTC+00:00) Bissau',
    zones: ['Africa/Bissau']
  },
  {
    zoneId: 'Antarctica/Troll',
    label: '(UTC+00:00) Troll',
    zones: ['Antarctica/Troll']
  },
  {
    zoneId: 'Africa/Casablanca',
    label: '(UTC+00:00) Casablanca',
    zones: ['Africa/Casablanca']
  },
  {
    zoneId: 'Africa/El_Aaiun',
    label: '(UTC+00:00) El Aaiun',
    zones: ['Africa/El_Aaiun']
  },
  {
    zoneId: 'WET',
    label: '(UTC+00:00) Western European Time',
    zones: ['WET']
  },
  {
    zoneId: 'Africa/Accra',
    label: '(UTC+00:00) Accra',
    zones: ['Africa/Accra']
  },
  {
    zoneId: 'Atlantic/Reykjavik',
    label: '(UTC+00:00) Reykjavik',
    zones: ['Atlantic/Reykjavik']
  },
  {
    zoneId: 'Atlantic/St_Helena',
    label: '(UTC+00:00) St Helena, Banjul, Freetown, Bamako, Conakry, Nouakchott, Abidjan, Ouagadougou, Lome, Dakar',
    zones: ['Atlantic/St_Helena',
            'Africa/Banjul',
            'Africa/Freetown',
            'Africa/Bamako',
            'Africa/Conakry',
            'Africa/Nouakchott',
            'Africa/Abidjan',
            'Africa/Ouagadougou',
            'Africa/Lome',
            'Africa/Dakar']
  },
  {
    zoneId: 'Atlantic/Canary',
    label: '(UTC+00:00) Canary',
    zones: ['Atlantic/Canary']
  },
  {
    zoneId: 'Europe/Budapest',
    label: '(UTC+01:00) Budapest',
    zones: ['Europe/Budapest']
  },
  {
    zoneId: 'Europe/Andorra',
    label: '(UTC+01:00) Andorra',
    zones: ['Europe/Andorra']
  },
  {
    zoneId: 'Europe/Monaco',
    label: '(UTC+01:00) Monaco',
    zones: ['Europe/Monaco']
  },
  {
    zoneId: 'Europe/Copenhagen',
    label: '(UTC+01:00) Copenhagen',
    zones: ['Europe/Copenhagen']
  },
  {
    zoneId: 'Europe/Luxembourg',
    label: '(UTC+01:00) Luxembourg',
    zones: ['Europe/Luxembourg']
  },
  {
    zoneId: 'Europe/Skopje',
    label: '(UTC+01:00) Skopje, Sarajevo, Ljubljana, Zagreb, Podgorica, Belgrade',
    zones: ['Europe/Skopje',
            'Europe/Sarajevo',
            'Europe/Ljubljana',
            'Europe/Zagreb',
            'Europe/Podgorica',
            'Europe/Belgrade']
  },
  {
    zoneId: 'Africa/Algiers',
    label: '(UTC+01:00) Algiers',
    zones: ['Africa/Algiers']
  },
  {
    zoneId: 'Africa/Sao_Tome',
    label: '(UTC+01:00) Sao Tome',
    zones: ['Africa/Sao_Tome']
  },
  {
    zoneId: 'Africa/Ndjamena',
    label: '(UTC+01:00) Ndjamena',
    zones: ['Africa/Ndjamena']
  },
  {
    zoneId: 'Africa/Ceuta',
    label: '(UTC+01:00) Ceuta, Madrid',
    zones: ['Africa/Ceuta', 'Europe/Madrid']
  },
  {
    zoneId: 'CET',
    label: '(UTC+01:00) Central European Time, Middle Europe Time',
    zones: ['CET', 'MET']
  },
  {
    zoneId: 'Europe/Malta',
    label: '(UTC+01:00) Malta',
    zones: ['Europe/Malta']
  },
  {
    zoneId: 'Europe/Oslo',
    label: '(UTC+01:00) Oslo, Longyearbyen',
    zones: ['Europe/Oslo', 'Arctic/Longyearbyen']
  },
  {
    zoneId: 'Europe/Rome',
    label: '(UTC+01:00) Rome, San Marino, Vatican',
    zones: ['Europe/Rome', 'Europe/San_Marino', 'Europe/Vatican']
  },
  {
    zoneId: 'Africa/Tunis',
    label: '(UTC+01:00) Tunis',
    zones: ['Africa/Tunis']
  },
  {
    zoneId: 'Europe/Stockholm',
    label: '(UTC+01:00) Stockholm',
    zones: ['Europe/Stockholm']
  },
  {
    zoneId: 'Europe/Vienna',
    label: '(UTC+01:00) Vienna',
    zones: ['Europe/Vienna']
  },
  {
    zoneId: 'Africa/Malabo',
    label: '(UTC+01:00) Malabo, Lagos, Libreville, Douala, Brazzaville, Porto-Novo, Luanda, Kinshasa, Bangui, Niamey',
    zones: ['Africa/Malabo',
            'Africa/Lagos',
            'Africa/Libreville',
            'Africa/Douala',
            'Africa/Brazzaville',
            'Africa/Porto-Novo',
            'Africa/Luanda',
            'Africa/Kinshasa',
            'Africa/Bangui',
            'Africa/Niamey']
  },
  {
    zoneId: 'Europe/Warsaw',
    label: '(UTC+01:00) Warsaw',
    zones: ['Europe/Warsaw']
  },
  {
    zoneId: 'Europe/Tirane',
    label: '(UTC+01:00) Tirane',
    zones: ['Europe/Tirane']
  },
  {
    zoneId: 'Europe/Busingen',
    label: '(UTC+01:00) Busingen, Zurich, Vaduz, Berlin',
    zones: ['Europe/Busingen', 'Europe/Zurich', 'Europe/Vaduz', 'Europe/Berlin']
  },
  {
    zoneId: 'Europe/Amsterdam',
    label: '(UTC+01:00) Amsterdam',
    zones: ['Europe/Amsterdam']
  },
  {
    zoneId: 'Europe/Prague',
    label: '(UTC+01:00) Prague, Bratislava',
    zones: ['Europe/Prague', 'Europe/Bratislava']
  },
  {
    zoneId: 'Europe/Gibraltar',
    label: '(UTC+01:00) Gibraltar',
    zones: ['Europe/Gibraltar']
  },
  {
    zoneId: 'Europe/Paris',
    label: '(UTC+01:00) Paris',
    zones: ['Europe/Paris']
  },
  {
    zoneId: 'Europe/Brussels',
    label: '(UTC+01:00) Brussels',
    zones: ['Europe/Brussels']
  },
  {
    zoneId: 'Europe/Mariehamn',
    label: '(UTC+02:00) Mariehamn, Helsinki',
    zones: ['Europe/Mariehamn', 'Europe/Helsinki']
  },
  {
    zoneId: 'Africa/Windhoek',
    label: '(UTC+02:00) Windhoek',
    zones: ['Africa/Windhoek']
  },
  {
    zoneId: 'Europe/Tallinn',
    label: '(UTC+02:00) Tallinn',
    zones: ['Europe/Tallinn']
  },
  {
    zoneId: 'Asia/Jerusalem',
    label: '(UTC+02:00) Jerusalem',
    zones: ['Asia/Jerusalem']
  },
  {
    zoneId: 'Asia/Nicosia',
    label: '(UTC+02:00) Nicosia, Nicosia',
    zones: ['Asia/Nicosia', 'Europe/Nicosia']
  },
  {
    zoneId: 'Africa/Kigali',
    label: '(UTC+02:00) Kigali, Lubumbashi, Gaborone, Maputo, Bujumbura, Blantyre, Lusaka, Harare',
    zones: ['Africa/Kigali',
            'Africa/Lubumbashi',
            'Africa/Gaborone',
            'Africa/Maputo',
            'Africa/Bujumbura',
            'Africa/Blantyre',
            'Africa/Lusaka',
            'Africa/Harare']
  },
  {
    zoneId: 'Africa/Mbabane',
    label: '(UTC+02:00) Mbabane, Maseru, Johannesburg',
    zones: ['Africa/Mbabane', 'Africa/Maseru', 'Africa/Johannesburg']
  },
  {
    zoneId: 'Europe/Athens',
    label: '(UTC+02:00) Athens',
    zones: ['Europe/Athens']
  },
  {
    zoneId: 'Europe/Vilnius',
    label: '(UTC+02:00) Vilnius',
    zones: ['Europe/Vilnius']
  },
  {
    zoneId: 'Europe/Chisinau',
    label: '(UTC+02:00) Chisinau',
    zones: ['Europe/Chisinau']
  },
  {
    zoneId: 'Africa/Khartoum',
    label: '(UTC+02:00) Khartoum',
    zones: ['Africa/Khartoum']
  },
  {
    zoneId: 'Asia/Amman',
    label: '(UTC+02:00) Amman',
    zones: ['Asia/Amman']
  },
  {
    zoneId: 'Asia/Damascus',
    label: '(UTC+02:00) Damascus',
    zones: ['Asia/Damascus']
  },
  {
    zoneId: 'EET',
    label: '(UTC+02:00) Eastern European Time',
    zones: ['EET']
  },
  {
    zoneId: 'Europe/Zaporozhye',
    label: '(UTC+02:00) Zaporozhye, Uzhgorod, Kiev',
    zones: ['Europe/Zaporozhye', 'Europe/Uzhgorod', 'Europe/Kiev']
  },
  {
    zoneId: 'Europe/Kaliningrad',
    label: '(UTC+02:00) Kaliningrad',
    zones: ['Europe/Kaliningrad']
  },
  {
    zoneId: 'Asia/Famagusta',
    label: '(UTC+02:00) Famagusta',
    zones: ['Asia/Famagusta']
  },
  {
    zoneId: 'Europe/Riga',
    label: '(UTC+02:00) Riga',
    zones: ['Europe/Riga']
  },
  {
    zoneId: 'Europe/Bucharest',
    label: '(UTC+02:00) Bucharest',
    zones: ['Europe/Bucharest']
  },
  {
    zoneId: 'Europe/Sofia',
    label: '(UTC+02:00) Sofia',
    zones: ['Europe/Sofia']
  },
  {
    zoneId: 'Asia/Beirut',
    label: '(UTC+02:00) Beirut',
    zones: ['Asia/Beirut']
  },
  {
    zoneId: 'Africa/Tripoli',
    label: '(UTC+02:00) Tripoli',
    zones: ['Africa/Tripoli']
  },
  {
    zoneId: 'Asia/Gaza',
    label: '(UTC+02:00) Gaza, Hebron',
    zones: ['Asia/Gaza', 'Asia/Hebron']
  },
  {
    zoneId: 'Africa/Cairo',
    label: '(UTC+02:00) Cairo',
    zones: ['Africa/Cairo']
  },
  {
    zoneId: 'Asia/Baghdad',
    label: '(UTC+03:00) Baghdad',
    zones: ['Asia/Baghdad']
  },
  {
    zoneId: 'Asia/Qatar',
    label: '(UTC+03:00) Qatar, Bahrain',
    zones: ['Asia/Qatar', 'Asia/Bahrain']
  },
  {
    zoneId: 'Asia/Aden',
    label: '(UTC+03:00) Aden, Riyadh, Kuwait',
    zones: ['Asia/Aden', 'Asia/Riyadh', 'Asia/Kuwait']
  },
  {
    zoneId: 'Europe/Moscow',
    label: '(UTC+03:00) Moscow, Simferopol, Volgograd',
    zones: ['Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd']
  },
  {
    zoneId: 'Antarctica/Syowa',
    label: '(UTC+03:00) Syowa',
    zones: ['Antarctica/Syowa']
  },
  {
    zoneId: 'Europe/Kirov',
    label: '(UTC+03:00) Kirov',
    zones: ['Europe/Kirov']
  },
  {
    zoneId: 'Africa/Juba',
    label: '(UTC+03:00) Juba',
    zones: ['Africa/Juba']
  },
  {
    zoneId: 'Africa/Nairobi',
    label: '(UTC+03:00) Nairobi, Comoro, Mogadishu, Djibouti, Asmara, Dar es Salaam, Addis Ababa, Kampala, Antananarivo, Mayotte',
    zones: ['Africa/Nairobi',
            'Indian/Comoro',
            'Africa/Mogadishu',
            'Africa/Djibouti',
            'Africa/Asmara',
            'Africa/Dar_es_Salaam',
            'Africa/Addis_Ababa',
            'Africa/Kampala',
            'Indian/Antananarivo',
            'Indian/Mayotte']
  },
  {
    zoneId: 'Europe/Istanbul',
    label: '(UTC+03:00) Istanbul, Istanbul',
    zones: ['Europe/Istanbul', 'Asia/Istanbul']
  },
  {
    zoneId: 'Europe/Minsk',
    label: '(UTC+03:00) Minsk',
    zones: ['Europe/Minsk']
  },
  {
    zoneId: 'Asia/Tehran',
    label: '(UTC+03:30) Tehran',
    zones: ['Asia/Tehran']
  },
  {
    zoneId: 'Asia/Tbilisi',
    label: '(UTC+04:00) Tbilisi',
    zones: ['Asia/Tbilisi']
  },
  {
    zoneId: 'Indian/Reunion',
    label: '(UTC+04:00) Reunion',
    zones: ['Indian/Reunion']
  },
  {
    zoneId: 'Asia/Baku',
    label: '(UTC+04:00) Baku',
    zones: ['Asia/Baku']
  },
  {
    zoneId: 'Asia/Dubai',
    label: '(UTC+04:00) Dubai, Muscat',
    zones: ['Asia/Dubai', 'Asia/Muscat']
  },
  {
    zoneId: 'Europe/Saratov',
    label: '(UTC+04:00) Saratov, Astrakhan, Ulyanovsk',
    zones: ['Europe/Saratov', 'Europe/Astrakhan', 'Europe/Ulyanovsk']
  },
  {
    zoneId: 'Europe/Samara',
    label: '(UTC+04:00) Samara',
    zones: ['Europe/Samara']
  },
  {
    zoneId: 'Indian/Mahe',
    label: '(UTC+04:00) Mahe',
    zones: ['Indian/Mahe']
  },
  {
    zoneId: 'Indian/Mauritius',
    label: '(UTC+04:00) Mauritius',
    zones: ['Indian/Mauritius']
  },
  {
    zoneId: 'Asia/Yerevan',
    label: '(UTC+04:00) Yerevan',
    zones: ['Asia/Yerevan']
  },
  {
    zoneId: 'Asia/Kabul',
    label: '(UTC+04:30) Kabul',
    zones: ['Asia/Kabul']
  },
  {
    zoneId: 'Antarctica/Mawson',
    label: '(UTC+05:00) Mawson',
    zones: ['Antarctica/Mawson']
  },
  {
    zoneId: 'Asia/Yekaterinburg',
    label: '(UTC+05:00) Yekaterinburg',
    zones: ['Asia/Yekaterinburg']
  },
  {
    zoneId: 'Asia/Karachi',
    label: '(UTC+05:00) Karachi',
    zones: ['Asia/Karachi']
  },
  {
    zoneId: 'Asia/Ashgabat',
    label: '(UTC+05:00) Ashgabat',
    zones: ['Asia/Ashgabat']
  },
  {
    zoneId: 'Indian/Maldives',
    label: '(UTC+05:00) Maldives',
    zones: ['Indian/Maldives']
  },
  {
    zoneId: 'Indian/Kerguelen',
    label: '(UTC+05:00) Kerguelen',
    zones: ['Indian/Kerguelen']
  },
  {
    zoneId: 'Asia/Aqtobe',
    label: '(UTC+05:00) Aqtobe',
    zones: ['Asia/Aqtobe']
  },
  {
    zoneId: 'Asia/Dushanbe',
    label: '(UTC+05:00) Dushanbe',
    zones: ['Asia/Dushanbe']
  },
  {
    zoneId: 'Asia/Oral',
    label: '(UTC+05:00) Oral',
    zones: ['Asia/Oral']
  },
  {
    zoneId: 'Asia/Samarkand',
    label: '(UTC+05:00) Samarkand, Tashkent',
    zones: ['Asia/Samarkand', 'Asia/Tashkent']
  },
  {
    zoneId: 'Asia/Atyrau',
    label: '(UTC+05:00) Atyrau',
    zones: ['Asia/Atyrau']
  },
  {
    zoneId: 'Asia/Aqtau',
    label: '(UTC+05:00) Aqtau',
    zones: ['Asia/Aqtau']
  },
  {
    zoneId: 'Asia/Colombo',
    label: '(UTC+05:30) Colombo',
    zones: ['Asia/Colombo']
  },
  {
    zoneId: 'Asia/Kolkata',
    label: '(UTC+05:30) Kolkata',
    zones: ['Asia/Kolkata']
  },
  {
    zoneId: 'Asia/Kathmandu',
    label: '(UTC+05:45) Kathmandu',
    zones: ['Asia/Kathmandu']
  },
  {
    zoneId: 'Asia/Urumqi',
    label: '(UTC+06:00) Urumqi',
    zones: ['Asia/Urumqi']
  },
  {
    zoneId: 'Asia/Thimphu',
    label: '(UTC+06:00) Thimphu',
    zones: ['Asia/Thimphu']
  },
  {
    zoneId: 'Asia/Omsk',
    label: '(UTC+06:00) Omsk',
    zones: ['Asia/Omsk']
  },
  {
    zoneId: 'Asia/Almaty',
    label: '(UTC+06:00) Almaty',
    zones: ['Asia/Almaty']
  },
  {
    zoneId: 'Antarctica/Vostok',
    label: '(UTC+06:00) Vostok',
    zones: ['Antarctica/Vostok']
  },
  {
    zoneId: 'Asia/Dhaka',
    label: '(UTC+06:00) Dhaka',
    zones: ['Asia/Dhaka']
  },
  {
    zoneId: 'Asia/Bishkek',
    label: '(UTC+06:00) Bishkek',
    zones: ['Asia/Bishkek']
  },
  {
    zoneId: 'Asia/Qyzylorda',
    label: '(UTC+06:00) Qyzylorda',
    zones: ['Asia/Qyzylorda']
  },
  {
    zoneId: 'Indian/Chagos',
    label: '(UTC+06:00) Chagos',
    zones: ['Indian/Chagos']
  },
  {
    zoneId: 'Asia/Yangon',
    label: '(UTC+06:30) Yangon',
    zones: ['Asia/Yangon']
  },
  {
    zoneId: 'Indian/Cocos',
    label: '(UTC+06:30) Cocos',
    zones: ['Indian/Cocos']
  },
  {
    zoneId: 'Indian/Christmas',
    label: '(UTC+07:00) Christmas',
    zones: ['Indian/Christmas']
  },
  {
    zoneId: 'Asia/Pontianak',
    label: '(UTC+07:00) Pontianak, Jakarta',
    zones: ['Asia/Pontianak', 'Asia/Jakarta']
  },
  {
    zoneId: 'Asia/Hovd',
    label: '(UTC+07:00) Hovd',
    zones: ['Asia/Hovd']
  },
  {
    zoneId: 'Asia/Novosibirsk',
    label: '(UTC+07:00) Novosibirsk',
    zones: ['Asia/Novosibirsk']
  },
  {
    zoneId: 'Asia/Phnom_Penh',
    label: '(UTC+07:00) Phnom Penh, Bangkok, Vientiane',
    zones: ['Asia/Phnom_Penh', 'Asia/Bangkok', 'Asia/Vientiane']
  },
  {
    zoneId: 'Asia/Novokuznetsk',
    label: '(UTC+07:00) Novokuznetsk, Krasnoyarsk',
    zones: ['Asia/Novokuznetsk', 'Asia/Krasnoyarsk']
  },
  {
    zoneId: 'Asia/Ho_Chi_Minh',
    label: '(UTC+07:00) Ho Chi Minh',
    zones: ['Asia/Ho_Chi_Minh']
  },
  {
    zoneId: 'Asia/Tomsk',
    label: '(UTC+07:00) Tomsk, Barnaul',
    zones: ['Asia/Tomsk', 'Asia/Barnaul']
  },
  {
    zoneId: 'Antarctica/Davis',
    label: '(UTC+07:00) Davis',
    zones: ['Antarctica/Davis']
  },
  {
    zoneId: 'Asia/Hong_Kong',
    label: '(UTC+08:00) Hong Kong',
    zones: ['Asia/Hong_Kong']
  },
  {
    zoneId: 'Asia/Macau',
    label: '(UTC+08:00) Macau',
    zones: ['Asia/Macau']
  },
  {
    zoneId: 'Asia/Shanghai',
    label: '(UTC+08:00) Shanghai',
    zones: ['Asia/Shanghai']
  },
  {
    zoneId: 'Australia/Perth',
    label: '(UTC+08:00) Perth',
    zones: ['Australia/Perth']
  },
  {
    zoneId: 'Asia/Irkutsk',
    label: '(UTC+08:00) Irkutsk',
    zones: ['Asia/Irkutsk']
  },
  {
    zoneId: 'Asia/Ulaanbaatar',
    label: '(UTC+08:00) Ulaanbaatar',
    zones: ['Asia/Ulaanbaatar']
  },
  {
    zoneId: 'Antarctica/Casey',
    label: '(UTC+08:00) Casey',
    zones: ['Antarctica/Casey']
  },
  {
    zoneId: 'Asia/Kuching',
    label: '(UTC+08:00) Kuching, Kuala Lumpur',
    zones: ['Asia/Kuching', 'Asia/Kuala_Lumpur']
  },
  {
    zoneId: 'Asia/Singapore',
    label: '(UTC+08:00) Singapore',
    zones: ['Asia/Singapore']
  },
  {
    zoneId: 'Asia/Brunei',
    label: '(UTC+08:00) Brunei',
    zones: ['Asia/Brunei']
  },
  {
    zoneId: 'Asia/Makassar',
    label: '(UTC+08:00) Makassar',
    zones: ['Asia/Makassar']
  },
  {
    zoneId: 'Asia/Choibalsan',
    label: '(UTC+08:00) Choibalsan',
    zones: ['Asia/Choibalsan']
  },
  {
    zoneId: 'Asia/Manila',
    label: '(UTC+08:00) Manila',
    zones: ['Asia/Manila']
  },
  {
    zoneId: 'Asia/Taipei',
    label: '(UTC+08:00) Taipei',
    zones: ['Asia/Taipei']
  },
  {
    zoneId: 'Australia/Eucla',
    label: '(UTC+08:45) Eucla',
    zones: ['Australia/Eucla']
  },
  {
    zoneId: 'Asia/Jayapura',
    label: '(UTC+09:00) Jayapura',
    zones: ['Asia/Jayapura']
  },
  {
    zoneId: 'Asia/Seoul',
    label: '(UTC+09:00) Seoul',
    zones: ['Asia/Seoul']
  },
  {
    zoneId: 'Asia/Dili',
    label: '(UTC+09:00) Dili',
    zones: ['Asia/Dili']
  },
  {
    zoneId: 'Asia/Chita',
    label: '(UTC+09:00) Chita, Yakutsk, Khandyga',
    zones: ['Asia/Chita', 'Asia/Yakutsk', 'Asia/Khandyga']
  },
  {
    zoneId: 'Asia/Tokyo',
    label: '(UTC+09:00) Tokyo',
    zones: ['Asia/Tokyo']
  },
  {
    zoneId: 'Pacific/Palau',
    label: '(UTC+09:00) Palau',
    zones: ['Pacific/Palau']
  },
  {
    zoneId: 'Asia/Pyongyang',
    label: '(UTC+09:00) Pyongyang',
    zones: ['Asia/Pyongyang']
  },
  {
    zoneId: 'Australia/Darwin',
    label: '(UTC+09:30) Darwin',
    zones: ['Australia/Darwin']
  },
  {
    zoneId: 'Antarctica/DumontDUrville',
    label: '(UTC+10:00) DumontDUrville',
    zones: ['Antarctica/DumontDUrville']
  },
  {
    zoneId: 'Pacific/Guam',
    label: '(UTC+10:00) Guam, Saipan',
    zones: ['Pacific/Guam', 'Pacific/Saipan']
  },
  {
    zoneId: 'Asia/Ust-Nera',
    label: '(UTC+10:00) Ust-Nera',
    zones: ['Asia/Ust-Nera']
  },
  {
    zoneId: 'Pacific/Port_Moresby',
    label: '(UTC+10:00) Port Moresby',
    zones: ['Pacific/Port_Moresby']
  },
  {
    zoneId: 'Australia/Brisbane',
    label: '(UTC+10:00) Brisbane, Lindeman',
    zones: ['Australia/Brisbane', 'Australia/Lindeman']
  },
  {
    zoneId: 'Pacific/Chuuk',
    label: '(UTC+10:00) Chuuk',
    zones: ['Pacific/Chuuk']
  },
  {
    zoneId: 'Asia/Vladivostok',
    label: '(UTC+10:00) Vladivostok',
    zones: ['Asia/Vladivostok']
  },
  {
    zoneId: 'Australia/Broken_Hill',
    label: '(UTC+10:30) Broken Hill',
    zones: ['Australia/Broken_Hill']
  },
  {
    zoneId: 'Australia/Adelaide',
    label: '(UTC+10:30) Adelaide',
    zones: ['Australia/Adelaide']
  },
  {
    zoneId: 'Australia/Melbourne',
    label: '(UTC+11:00) Melbourne',
    zones: ['Australia/Melbourne']
  },
  {
    zoneId: 'Australia/Hobart',
    label: '(UTC+11:00) Hobart',
    zones: ['Australia/Hobart']
  },
  {
    zoneId: 'Australia/Currie',
    label: '(UTC+11:00) Currie, Sydney',
    zones: ['Australia/Currie', 'Australia/Sydney']
  },
  {
    zoneId: 'Australia/Lord_Howe',
    label: '(UTC+11:00) Lord Howe',
    zones: ['Australia/Lord_Howe']
  },
  {
    zoneId: 'Asia/Magadan',
    label: '(UTC+11:00) Magadan',
    zones: ['Asia/Magadan']
  },
  {
    zoneId: 'Pacific/Guadalcanal',
    label: '(UTC+11:00) Guadalcanal',
    zones: ['Pacific/Guadalcanal']
  },
  {
    zoneId: 'Pacific/Noumea',
    label: '(UTC+11:00) Noumea',
    zones: ['Pacific/Noumea']
  },
  {
    zoneId: 'Antarctica/Macquarie',
    label: '(UTC+11:00) Macquarie',
    zones: ['Antarctica/Macquarie']
  },
  {
    zoneId: 'Asia/Sakhalin',
    label: '(UTC+11:00) Sakhalin',
    zones: ['Asia/Sakhalin']
  },
  {
    zoneId: 'Pacific/Norfolk',
    label: '(UTC+11:00) Norfolk',
    zones: ['Pacific/Norfolk']
  },
  {
    zoneId: 'Pacific/Efate',
    label: '(UTC+11:00) Efate',
    zones: ['Pacific/Efate']
  },
  {
    zoneId: 'Pacific/Bougainville',
    label: '(UTC+11:00) Bougainville',
    zones: ['Pacific/Bougainville']
  },
  {
    zoneId: 'Asia/Srednekolymsk',
    label: '(UTC+11:00) Srednekolymsk',
    zones: ['Asia/Srednekolymsk']
  },
  {
    zoneId: 'Pacific/Kosrae',
    label: '(UTC+11:00) Kosrae',
    zones: ['Pacific/Kosrae']
  },
  {
    zoneId: 'Pacific/Pohnpei',
    label: '(UTC+11:00) Pohnpei',
    zones: ['Pacific/Pohnpei']
  },
  {
    zoneId: 'Pacific/Funafuti',
    label: '(UTC+12:00) Funafuti',
    zones: ['Pacific/Funafuti']
  },
  {
    zoneId: 'Pacific/Nauru',
    label: '(UTC+12:00) Nauru',
    zones: ['Pacific/Nauru']
  },
  {
    zoneId: 'Pacific/Kwajalein',
    label: '(UTC+12:00) Kwajalein, Majuro',
    zones: ['Pacific/Kwajalein', 'Pacific/Majuro']
  },
  {
    zoneId: 'Asia/Anadyr',
    label: '(UTC+12:00) Anadyr',
    zones: ['Asia/Anadyr']
  },
  {
    zoneId: 'Pacific/Wake',
    label: '(UTC+12:00) Wake',
    zones: ['Pacific/Wake']
  },
  {
    zoneId: 'Pacific/Wallis',
    label: '(UTC+12:00) Wallis',
    zones: ['Pacific/Wallis']
  },
  {
    zoneId: 'Pacific/Fiji',
    label: '(UTC+12:00) Fiji',
    zones: ['Pacific/Fiji']
  },
  {
    zoneId: 'Pacific/Tarawa',
    label: '(UTC+12:00) Tarawa',
    zones: ['Pacific/Tarawa']
  },
  {
    zoneId: 'Asia/Kamchatka',
    label: '(UTC+12:00) Kamchatka',
    zones: ['Asia/Kamchatka']
  },
  {
    zoneId: 'Antarctica/McMurdo',
    label: '(UTC+13:00) McMurdo, Auckland',
    zones: ['Antarctica/McMurdo', 'Pacific/Auckland']
  },
  {
    zoneId: 'Pacific/Enderbury',
    label: '(UTC+13:00) Enderbury',
    zones: ['Pacific/Enderbury']
  },
  {
    zoneId: 'Pacific/Tongatapu',
    label: '(UTC+13:00) Tongatapu',
    zones: ['Pacific/Tongatapu']
  },
  {
    zoneId: 'Pacific/Fakaofo',
    label: '(UTC+13:00) Fakaofo',
    zones: ['Pacific/Fakaofo']
  },
  {
    zoneId: 'Pacific/Chatham',
    label: '(UTC+13:45) Chatham',
    zones: ['Pacific/Chatham']
  },
  {
    zoneId: 'Pacific/Apia',
    label: '(UTC+14:00) Apia',
    zones: ['Pacific/Apia']
  },
  {
    zoneId: 'Pacific/Kiritimati',
    label: '(UTC+14:00) Kiritimati',
    zones: ['Pacific/Kiritimati']
  }
];
