
export enum ProjectStatusEnum {

  ToDo = 'TODO',

  InProgress = 'IN_PROGRESS',

  Review = 'REVIEW',

  Done = 'DONE',

  Hold = 'HOLD',

}
