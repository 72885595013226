
export class SpinnerEventModel {

  constructor(
    public show: boolean,
    public blockScreen = true,
    public size = 70,
  ) { }

}
