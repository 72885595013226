import { FormContext } from '../modules/tpt-forms/base/form.context';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class ContactForm extends FormContext
  <{firstName: string, lastName: string; email: string, category: string, message: string}> {

  static createForm(): ContactForm {
    const form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'),
        Validators.maxLength(50),
      ]),
      message: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500)
      ]),
    });

    return new ContactForm(form);
  }

  public get firstName(): AbstractControl {
    return this.getControl('firstName');
  }

  public get lastName(): AbstractControl {
    return this.getControl('lastName');
  }

  public get email(): AbstractControl {
    return this.getControl('email');
  }

  public get category(): AbstractControl {
    return this.getControl('category');
  }

  public get message(): AbstractControl {
    return this.getControl('message');
  }

  constructor(
    public form: UntypedFormGroup,
  ) {
    super(form);
  }

  public getFormData(): {firstName: string, lastName: string; email: string, category: string, message: string} {
    return this.form.value;
  }

}
