<tpt-label *ngIf="showLabel">
  <ng-content select="[label]"></ng-content>
</tpt-label>

<mat-form-field
  [appearance]="appearance"
  floatLabel="never"
  [class.-error-state]="errorState"
>
  <input
    [formControl]="control"
    [matDatepicker]="deadlineDate"
    (click)="deadlineDate.open()"
    [max]="max"
    [min]="min"
    [placeholder]="placeholder"
    matInput
  >
  <mat-datepicker-toggle matSuffix [for]="deadlineDate">
    <mat-icon matDatepickerToggleIcon
              class="arrow-icon"
              *ngIf="arrowIcon"
              [svgIcon]="svgIconsEnum.SmallArrowDown"
    ></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #deadlineDate [panelClass]="panelClass"></mat-datepicker>
</mat-form-field>
<mat-error [class.-invisible]="!errorState">
  {{ error | translate}}
</mat-error>
