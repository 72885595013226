import { Directive, forwardRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function minLengthWithTrim(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value.length === 0) {
      return null;
    }
    const value = control.value.trim();

    const length: number = value ? value.length : 0;
    return length < minLength ?
      {minlength: {requiredLength: minLength, actualLength: length}} :
      null;
  };
}

export const MIN_LENGTH_WITH_TRIM_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinLengthWithTrimValidator),
  multi: true
};

@Directive({
  selector: '[xlnMinlengthTrim][formControlName],[xlnMinlengthTrim][formControl],[xlnMinlengthTrim][ngModel]',
  providers: [MIN_LENGTH_WITH_TRIM_VALIDATOR],
})
export class MinLengthWithTrimValidator implements Validator, OnChanges {

  @HostBinding('attr.xlnMinlengthTrim')
  @Input() xlnMinlengthTrim: string;
  private _validator: ValidatorFn;
  private _onChange: () => void;

  ngOnChanges(changes: SimpleChanges): void {
    if ('xlnMinlengthTrim' in changes) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.xlnMinlengthTrim == null ? null : this._validator(control);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = minLengthWithTrim(parseInt(this.xlnMinlengthTrim, 10));
  }

}
