<div *ngIf="message" class="alert"
     [ngClass]="{'alert-success': type === 'success', 'alert-danger': type === 'error' }">
  {{message | translate:params}}
</div>

<mat-form-field *ngIf="textPlaceholder" class="alert__reason">
  <textarea matInput [(ngModel)]="reason" rows="2" name="reason"
            placeholder="{{textPlaceholder | translate}}">
  </textarea>
</mat-form-field>
<br>
<button *ngIf="type != 'custom'" type="button" mat-flat-button
        (click)="dialogRef.close(false)">{{'BUTTON.CLOSE' | translate}}</button>
<div *ngIf="type == 'custom'" class="alert__buttons">
  <button *ngFor="let button of buttons"
          mat-flat-button (click)="dialogRef.close(button.result && !!reason ? reason : button.result)"
          [color]="button.color" class="alert__button" ngClass="{{button.cssClass}}">{{button.title | translate}}
  </button>
</div>
