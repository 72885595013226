import { Component, Input } from '@angular/core';
import { TaskCommentModel } from '../../../employer/models/task-comments/task-comment.model';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import * as moment from 'moment';
import { TaskStatusesEnum } from '../../../../types/task_statuses.enum';

@Component({
  selector: 'emp-own-message',
  templateUrl: './own-message.component.html',
  styleUrls: [ './own-message.component.scss' ],
})
export class OwnMessageComponent {

  @Input()
  public message: TaskCommentModel;

  public svgIconsEnum = SvgIconsEnum;
  public statusEnum = TaskStatusesEnum;

  public get sendDate(): string {
    return moment(this.message.date).format('DD.MM.YYYY HH:mm');
  }

  public get isTextMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.text';
  }

  public get isChangeMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.change_status';
  }

  public get isDeclineMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.decline';
  }

  public get isOpenDisputeMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.open_dispute';
  }

  public get isAcceptMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.accept';
  }

  public get isStartArbitrageMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.start_arbitrage';
  }

  public get isReopenArbitrageMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.reopen_arbitrage';
  }

  public get isArbitrageDecisionMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.arbitrator_decision';
  }

  public getClassByStatus(status: TaskStatusesEnum): string {

    switch (status) {
      case TaskStatusesEnum.TODO:
        return '-todo';
      case TaskStatusesEnum.IN_PROGRESS:
        return '-in-progress';
      case TaskStatusesEnum.REVIEW:
        return '-review';
      case TaskStatusesEnum.HOLD:
        return '-hold';
      case TaskStatusesEnum.DONE:
        return '-done';
    }
  }
}
