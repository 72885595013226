import { Component } from '@angular/core';

@Component({
  selector: 'contracts-not-selected-block',
  templateUrl: './contracts-not-selected-block.component.html',
  styleUrls: [ './contracts-not-selected-block.component.scss' ],
})
export class ContractsNotSelectedBlockComponent {

}
