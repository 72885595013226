<div
  class="days-remaining"
  [class.-primary]="isPrimary"
  [class.-success]="isSuccess"
  [class.-warn]="isWarn"
  [class.-err]="isErr"
>
  <mat-icon [svgIcon]="svgIconsEnum.Clock"></mat-icon>
  <span class="days-label">
    {{ daysLeft }}
    {{'FINANCE.DAYS_LEFT' | translate}}
  </span>
</div>
