<div *ngIf="!!profileService.currentProfile" class="notification">
  <div class="notification__notification"
     (click)="show($event)">
    <button mat-icon-button class="button">
      <mat-icon [svgIcon]="svgIconsEnum.Bell" class="icon"></mat-icon>
    </button>
  </div>

  <div class="notification__status" *ngIf="count>0">{{count > 99 ? '99+' : count}}</div>
</div>

