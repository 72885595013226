import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class DadataService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public suggestAddress(body): Observable<any> {
    return fromPromise(this.executePost<any>('v2/dadata/suggestAddress', body));
  }

}
