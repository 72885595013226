import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteHelper } from '../../helpers/route.helper';
import { ProjectSearchComponent } from './components/project-search/project-search.component';
import { FreelancerSearchComponent } from './components/freelancer-search/freelancer-search.component';
import { SearchComponent} from './search.component';

const routes: Routes = [
  {
    path: '',
    component: SearchComponent,
    children: [
      {
        path: RouteHelper.PROJECTS,
        component: ProjectSearchComponent,
      },
      {
        path: RouteHelper.FREELANCERS,
        component: FreelancerSearchComponent,
      },
      {
        path: RouteHelper.EMPTY_PATH,
        pathMatch: 'full',
        redirectTo: RouteHelper.PROJECTS,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchRoutingModule {
}
