<tpt-dialog>
  <div class="dialog">
    <div class="dialog-title">{{ 'CONTRACTS.PAYMENT_CONFIRM_TITLE' | translate}}</div>
    <div class="dialog-description">{{ 'CONTRACTS.PAYMENT_CONFIRM_TEXT' | translate}}</div>
    <div class="dialog-actions">
      <tpt-button
        type="secondary"
        class="button decline-button"
        (click)="close()"
      >
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button
        type="primary"
        class="button approve-button"
        (click)="approveAction()"
      >
        {{ 'CONTRACTS.PAYMENT_CONFIRM_BTN' | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
