import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { CurrencyModel } from '../../../../models/financev2/currency.model';
import { SpecializationModel } from '../../../../models/specialization.model';
import { CurrencySymbol } from '../../../../models/currency.enum';
import { ResourcesService } from '../../../../services/resources.service';

interface PaymentData {
  freelancer: {
    avatar: string,
    firstName: string,
    lastName: string,
    specializations: SpecializationModel[]
  };
  projectInfo: {
    id: number,
    name: string
  };
  details: {
    budget: number,
    budgetWithCommission: number,
    commissionValue: number,
    currency: CurrencyModel
  };
  qr: string;
}

@Component({
  selector: 'tpt-pay-sbp-dialog',
  templateUrl: './pay-sbp-dialog.component.html',
  styleUrls: ['./pay-sbp-dialog.component.scss']
})
export class PaySbpDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public currencySymbol = CurrencySymbol;

  public data: PaymentData;

  private config: MatDialogConfig = {
    width: '680px',
    height: '470px',
    panelClass: 'pay-sbp-dialog',
  };

  private className = 'tpt-pay-sbp-dialog';

  constructor(private resource: ResourcesService) {
  }

  public open(data?): void {
    this.data = data;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public getMainSpec(specs) {
    return specs.find(item => item.main);
  }

  public getUrl(fileId: string): string{
    return this.resource.getUrl(fileId);
  }

}
