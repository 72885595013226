import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-review-days-left',
  templateUrl: './review-days-left.component.html',
  styleUrls: ['./review-days-left.component.scss']
})
export class ReviewDaysLeftComponent  {

  @Input()
  public reviewDaysLeft: number;

  public svgIconsEnum = SvgIconsEnum;

  public get isGray(): boolean {
    return this.reviewDaysLeft > 4;
  }

  public get isWarn(): boolean {
    return this.reviewDaysLeft >= 3 && this.reviewDaysLeft < 5;
  }

  public get isErr(): boolean {
    return this.reviewDaysLeft < 3;
  }
}
