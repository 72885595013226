import {Injectable} from '@angular/core';

export interface IProfileService {
  logout(retUtl?: boolean);
}

@Injectable({
  providedIn: 'root',
})
export class LogoutService {

  private static profileService: IProfileService;

  public static registerService(profileService: IProfileService) {
    if (!LogoutService.profileService) {
      LogoutService.profileService = profileService;
    }
  }

  public static logout(retUrl: boolean = true) {
    if (LogoutService.profileService) {
      LogoutService.profileService.logout(retUrl);
    }
  }
}
