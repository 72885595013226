import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../services/base.service';
import { TaskCommentsRequestModel } from '../../models/task-comments/task-comments-request.model';
import { Observable } from 'rxjs';
import { TaskCommentsResponseModel } from '../../models/task-comments/task-comments-response.model';
import { SendTaskCommentsResponseModel } from '../../models/task-comments/send-task-comments-response.model';
import { SendTaskCommentsRequestModel } from '../../models/task-comments/send-task-comments-request.model';

@Injectable()
export class CommentsApiService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getTaskComments = (request: TaskCommentsRequestModel): Observable<TaskCommentsResponseModel> => {
    const params = { params: { taskId: request.taskId }};
    return this.executeGet('v2/comments', params);
  }

  public sendTaskComments = (request: SendTaskCommentsRequestModel): Observable<SendTaskCommentsResponseModel> => {
    return this.executePut('v2/comments', request);
  }

}
