<div
  class="days-remaining"
  [class.-gray]="isGray"
  [class.-warn]="isWarn"
  [class.-err]="isErr"
>
  <mat-icon [svgIcon]="svgIconsEnum.Clock"></mat-icon>
  <span class="days-label">
    {{'TRACKER.REVIEW_DAYS_LEFT' | translate : {count: reviewDaysLeft} }}
  </span>
</div>
