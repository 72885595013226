import {
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output,
} from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { NoteItemModel } from '../../employer/models/project-notes/note-item.model';
import * as moment from 'moment';

@Component({
  selector: 'emp-project-note',
  templateUrl: './project-note.component.html',
  styleUrls: [ './project-note.component.scss' ],
})
export class ProjectNoteComponent {

  @Input()
  public note: NoteItemModel;

  @Input()
  public activeNote: boolean;

  @Output()
  public readonly deleteNote = new EventEmitter<NoteItemModel>();

  @Output()
  public readonly pinNote = new EventEmitter<NoteItemModel>();

  @Output()
  public readonly unpinNote = new EventEmitter<NoteItemModel>();

  public svgIconsEnum = SvgIconsEnum;

  public get noteCreateTime(): string {
    return moment(this.note.date).format('DD.MM.YYYY HH:mm');
  }
}
