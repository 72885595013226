import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'tpt-label',
  template: `
    <label class="label">
      <ng-content></ng-content>
    </label>
  `,
  styleUrls: [ './label.component.scss' ],
})
export class LabelComponent {

  @HostBinding('class.tpt-label')
  public baseClass = true;

}
