import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class PortalService implements OnDestroy {

  public readonly headerPortal = new BehaviorSubject<TemplatePortal | null>(null);

  public readonly sidebarPortal = new BehaviorSubject<TemplatePortal | null>(null);

  ngOnDestroy(): void {
    if (this.headerPortal) {
      this.headerPortal.next(null);
      this.headerPortal.complete();
    }

    if (this.sidebarPortal) {
      this.sidebarPortal.next(null);
      this.sidebarPortal.complete();
    }
  }

  public attachToHeaderPortal = (templatePortal: TemplatePortal): void => {
    this.headerPortal.next(templatePortal);
  }

  public detachHeaderPortal = (): void => {
    this.headerPortal.next(null);
  }

  public attachToSidebarPortal = (templatePortal: TemplatePortal): void => {
    this.sidebarPortal.next(templatePortal);
  }

  public detachSidebarPortal = (): void => {
    this.sidebarPortal.next(null);
  }

}
