import { Component } from '@angular/core';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { SchemalessService } from '../../services/schemaless.service';
import { SpinnerService } from '../../modules/tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '../../services/snackbar-notifications.service';

@Component({
  selector: 'tpt-user-poll',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent {

  public initValue = {
    name: null,
    surname: null,
    givenNames: null,
    birthDate: null,
    citizenShip: null,
    permanentlyResiding: null,
    taxResidentOf: null,
    taxExtraCountry: null,
    ITNinCountryResidence: null,
    isRegisteredAsLegalEntity: null,
    taxNumberOfLegalEntity: null,
    willBeEmployedAsLegal: null,
    needAssistanceInRegisteringAsLegal: null,
    engagementModel: null,
    amountOfWorkingHours: null,
    limitations: null,
    needWorkspace: null,
    workEquipment: null,
    startDate: null,
    education: [{
      name: null,
      period: null,
      degree: null,
      mentorContacts: null
    }],
    workExperience: [{
      company: null,
      period: null,
      position: null,
      employmentForm: null,
      reference: null
    }]
  };

  public svgIconsEnum = SvgIconsEnum;

  public checkList = this.initValue;

  public confirmInfo = false;

  public confirmStorageData = false;

  constructor(private schemalessService: SchemalessService,
              private snack: SnackbarNotificationsService,
              private spinner: SpinnerService) { }

  public saveData(): void {
    const data = JSON.stringify(this.checkList);

    this.spinner.startSpinner();
    this.schemalessService.saveCheckList(data).subscribe(() => {
      this.spinner.stopSpinner();
      this.snack.showNotification('The data was successfully sent.', 'success');
    }, () => {
      this.spinner.stopSpinner();
    });
  }

  public addEducation(): void {
    this.checkList.education.push({
      name: null,
      period: null,
      degree: null,
      mentorContacts: null
    });
  }

  public removeEducation(index: number): void {
    this.checkList.education.splice(index, 1);
  }

  public addExperience(): void {
    this.checkList.workExperience.push({
      company: null,
      period: null,
      position: null,
      employmentForm: null,
      reference: null
    });
  }

  public removeExperience(index: number): void {
    this.checkList.workExperience.splice(index, 1);
  }

}
