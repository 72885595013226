import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';

export function deserializationJacksonKey<T extends object, V extends any[]>(cls: ClassType<T>,
                                                                             plain: V,
                                                                             options?): T[];
export function deserializationJacksonKey<T extends object, V>(cls: ClassType<T>, plain: V): T;
export function deserializationJacksonKey<T extends object, V>(cls: ClassType<T>, plain: V | V[]): T | T[] {
  const map = new Map();
  const object = plainToClass(cls, plain, {enableCircularCheck: true});
  fillMap(object, map);
  return deserializationObject(object, map);
}

export function serializationJacksonKey<T extends object>(object: T): object | string;
export function serializationJacksonKey<T extends object>(object: T[]): object[];
export function serializationJacksonKey<T extends object>(object: T | T[]): object | object[] | string {
  let o: object | object[];
  if (object) {
    o = Object.assign({}, object);
  } else {
    o = Object.assign([], object);
  }
  const keys = new Set<string>();
  return classToPlain(serializationObject(o, keys));
}

function serializationObject(o, keys: Set<string>): object | object[] | string {
  try {
    if (o instanceof Array) {
      o = o.slice();
      for (const i in o) {
        if (o.hasOwnProperty(i)) {
          o[i] = serializationObject(o[i], keys);
        }
      }
    } else if (typeof o === 'object' && o) {
      if (o instanceof Date) {
        return o.toISOString();
      } else {
        o = Object.assign({}, o);
      }

      const key = o.key;

      if (key) {
        if (keys.has(key)) {
          return key;
        } else {
          keys.add(key);
        }
      }

      Object.keys(o).sort().forEach((property) => {
        if (o.hasOwnProperty(property) && property !== 'key') {
          o[property] = serializationObject(o[property], keys);
        }
      });
    }

  } catch (e) {
    console.log(e);
  }

  return o;
}

function fillMap(o, map: Map<string, object>) {
  try {
    if (o != null) {
      if (o instanceof Array) {
        for (const i in o) {
          if (o.hasOwnProperty(i)) {
            fillMap(o[i], map);
          }
        }
      } else if (typeof o === 'object') {
        if (o.key) {
          map.set(o.key, o);
        }
        for (const property in o) {
          if (o.hasOwnProperty(property) && property !== 'key') {
            fillMap(o[property], map);
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function deserializationObject(o, map: Map<string, object>): any {
  try {
    if (o != null) {
      if (o instanceof Array) {
        for (const i in o) {
          if (o.hasOwnProperty(i)) {
            o[i] = deserializationObject(o[i], map);
          }
        }
      } else if (typeof o === 'object') {
        for (const property in o) {
          if (o.hasOwnProperty(property) && property !== 'key') {
            o[property] = deserializationObject(o[property], map);
          }
        }
      } else if (typeof o === 'string') {
        if (map.get(o)) {
          return map.get(o);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }

  return o;
}

export function replacer(key, value) {
  if (value == null || value.constructor !== Object) {
    return value;
  }
  return Object.keys(value).sort().reduce((s, k) => {
    s[k] = value[k];
    return s;
  }, {});
}
