import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tptDetectUrl'
})
export class DetectUrlPipe implements PipeTransform {

  public transform(str: string): any {
    if (!str) {
      return;
    }

    const match = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    let final = str;
    match?.map(url => {
      final = final.replace(url, '<a href="' + url + '" target="_BLANK" class="detected-link">' + url + '</a>');
    });
    return final;
  }

}
