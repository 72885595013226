import { Component, Input, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-success-action-dialog',
  templateUrl: './success-action-dialog.component.html',
  styleUrls: ['./success-action-dialog.component.scss']
})
export class SuccessActionDialogComponent {

  @Input() public title: string;

  @Input() public text: string;

  @ViewChild(DialogComponent, { static: false })
  public dialog: DialogComponent;

  private readonly className = 'tpt-success-action-dialog';

  private config: MatDialogConfig = {
    width: '620px',
  };

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
