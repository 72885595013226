<div
  *ngIf="isTextMessage"
  class="message"
>

  <div class="message-header">
    <span class="send-date">
      {{ sendDate }}
    </span>
  </div>

  <div class="message-body">
    <div [innerHTML]="message?.content?.body | tptDetectUrl | safeHtml"></div>

    <div
      *ngFor="let file of message.content?.files"
      class="message-body-files"
    >
      <tpt-uploaded-file [file]="file"></tpt-uploaded-file>
    </div>
  </div>

</div>

<ng-container *ngIf="isChangeMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body" *ngIf="message?.sender?.id">
      {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      {{'TRACKER.CHANGED_STATUS' | translate}}
      <span class="status" [ngClass]="getClassByStatus(statusEnum[message?.content?.status])">
        {{('TRACKER.' + message?.content?.status) | translate}}</span>
    </div>
    <div class="status-change-body" *ngIf="!message?.sender?.id">
      {{'TRACKER.STATUS_AUTO_CHANGED' | translate}}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isDeclineMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      <span class="status -hold">{{'TRACKER.DECLINED_TASK' | translate}}</span>
    </div>
  </div>
  <div class="reason-wrap" *ngIf="message.content.body as message">
    <span class="reason">{{'TRACKER.REASON' | translate}}</span>
    <div class="message-body">{{ message }}</div>
  </div>
</ng-container>

<ng-container *ngIf="isOpenDisputeMessage || isStartArbitrageMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body" [ngClass]="{'dispute': isOpenDisputeMessage, 'arbitrage': isStartArbitrageMessage}">
      <mat-icon [svgIcon]="isOpenDisputeMessage ? svgIconsEnum.Bubble : svgIconsEnum.Mustache "></mat-icon>
      {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      {{(isOpenDisputeMessage ? 'TRACKER.OPENED_DISPUTE' : 'TRACKER.OPENED_MEDIATION') | translate}}
    </div>
  </div>
  <div class="reason-wrap" *ngIf="message.content.body as message">
    <span class="reason">{{'TRACKER.REASON' | translate}}</span>
    <div class="message-body">{{ message }}</div>
  </div>
</ng-container>

<ng-container *ngIf="isArbitrageDecisionMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body made-decision">
      <mat-icon [svgIcon]="svgIconsEnum.Mustache"></mat-icon>
      {{'TRACKER.MADE_DECISION_COMMENT' | translate}}
    </div>
  </div>
  <div class="reason-wrap" *ngIf="message.content.body as message">
    <div class="message-body">{{ message }}</div>
  </div>
</ng-container>

<ng-container *ngIf="isReopenArbitrageMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body arbitrage">
      <mat-icon [svgIcon]="svgIconsEnum.Mustache"></mat-icon>
      {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      {{'TRACKER.CHALLENGED_DECISION' | translate}}
    </div>
  </div>
  <div class="reason-wrap" *ngIf="message.content.body as message">
    <span class="reason">{{'TRACKER.REASON' | translate}}</span>
    <div class="message-body">{{ message }}</div>
  </div>
</ng-container>

<ng-container *ngIf="isAcceptMessage">
  <div class="status-change">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      <span class="status -done">{{'TRACKER.ACCEPTED_TASK' | translate}}</span>
    </div>
  </div>
</ng-container>
