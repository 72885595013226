import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  private isGoogleAnalyticsInitialized: boolean;

  private readonly googleAnalyticsId = environment.GTM_CODE;

  private readonly googleAnalyticsScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window, document, 'script', 'dataLayer', '${this.googleAnalyticsId}');`;

  private windowDataLayer: Array<any>;

  constructor() {
    this.initializeDataLayer();
    this.initializeGoogleAnalytics();
  }

  public pushToDataLayer = <T>(event: any): void => {
    this.windowDataLayer.push(event);

    if (!this.isGoogleAnalyticsInitialized) {
      this.initializeGoogleAnalytics();
    }
  }

  public initializeGoogleAnalytics(): void {
    if (this.isGoogleAnalyticsInitialized || !this.googleAnalyticsId) {
      return;
    }

    const script = this.createScript();
    this.appendScriptToDOM(script);
    this.isGoogleAnalyticsInitialized = true;
  }

  private initializeDataLayer(): void {
    (window as any).dataLayer = (window as any).dataLayer || [];
    this.windowDataLayer = (window as any).dataLayer;
  }

  private createScript(): HTMLScriptElement {
    const script = document.createElement('script');
    script.innerText = this.googleAnalyticsScript;

    return script;
  }

  private appendScriptToDOM(script: HTMLScriptElement): void {
    document.documentElement.firstChild.appendChild(script);
  }

}
