import { Pipe, PipeTransform } from '@angular/core';
import { LanguageLevelModel } from '../../../models/language-level.model';
import { LanguageLevelEnum } from '../../employer/types/language-level.enum';

@Pipe({
  name: 'tptLanguageLevels',
})
export class LanguageLevelsPipe implements PipeTransform {

  transform(languages: Array<LanguageLevelModel>, ...args: any[]): any {
    if (!languages || !languages.length) {
      return [];
    }

    return languages.map(this.languageWithMappedLevel);
  }

  private languageWithMappedLevel = ({ code, name, level }: LanguageLevelModel): { code: string, name: string, level: string } => {
    return { code, name, level: this.mapLanguageLevel(level) };
  }

  private mapLanguageLevel = (level: 'BEGINNER' | 'MEDIUM' | 'ADVANCED' | 'NATIVE'): string => {
    switch (level) {
      case 'BEGINNER':
        return LanguageLevelEnum.BEGINNER;
      case 'MEDIUM':
        return LanguageLevelEnum.MEDIUM;
      case 'ADVANCED':
        return LanguageLevelEnum.ADVANCED;
      case 'NATIVE':
        return LanguageLevelEnum.NATIVE;
    }
  }

}
