import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { Feedback } from '@/models/feedback/rating-variables';

@Component({
  selector: 'tpt-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss']
})
export class ReviewCardComponent {
  svgIconsEnum = SvgIconsEnum;

  @Input() public feedback: Feedback;
  @Output() public clickReview = new EventEmitter();
}
