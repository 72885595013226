import { Component, Input } from '@angular/core';
import { Feedback } from '@/models/feedback/rating-variables';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecializationModel } from '@/models/specialization.model';
import { SkillV2Model } from '@/models/skill-v2.model';

@Component({
  selector: 'tpt-feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss']
})
export class FeedbackCardComponent {

  svgIconsEnum = SvgIconsEnum;

  public employerStats = [
    {name: 'EMP_accuracy', field: 'accuracy'},
    {name: 'rapidity', field: 'rapidity'},
    {name: 'EMP_quality', field: 'quality'},
    {name: 'adequacy', field: 'adequacy'}
  ];

  public freelancerStats = [
    {name: 'FR_accuracy', field: 'accuracy'},
    {name: 'rapidity', field: 'rapidity'},
    {name: 'FR_quality', field: 'quality'},
    {name: 'adequacy', field: 'adequacy'}
  ];

  public moreSkillsCount = 0;

  public allSkillsShowed = false;

  @Input() public feedback: Feedback;
  @Input() public isEmployer: boolean;

  constructor(private profileService: ProfileService,
              private translate: TranslateService) { }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public skills(): (string | SkillV2Model)[] {
    const skills = this.feedback.jobInfo?.skills || [];
    const customSkills = this.feedback.jobInfo?.customSkills || [];

    const allSkills = [...skills, ...customSkills];
    const skillsLength = allSkills?.length;

    let showedSkills;
    if (skillsLength > 5 && !this.allSkillsShowed) {
      showedSkills = allSkills.slice(0, 5);
      this.moreSkillsCount = skillsLength - 5;
    } else {
      showedSkills = allSkills;
    }

    return showedSkills;
  }

  public showAllSkills(): void {
    this.allSkillsShowed = true;
    this.moreSkillsCount = 0;
  }
}
