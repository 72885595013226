import { Component, Input } from '@angular/core';
import { ProfileModel } from '../../../../models/profile.model';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input() user: ProfileModel;

  @Input() size: string;

  @Input() bgColor = '';

  @Input() fgColor = '#fff';

  @Input() textSizeRatio = 3;

  svgIconsEnum = SvgIconsEnum;
}
