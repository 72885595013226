import { DatePipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'localDate',
  pure: false, // required to update the value when the promise is resolved
})

export class LocalDatePipe implements PipeTransform, OnDestroy {
  protected datePipe: DatePipe;
  protected result: string | null = null;
  protected timezone: string = null;
  private subscription: Subscription;
  private value: any = null;
  private format: string = null;
  private locale: string = null;
  private markForChanges = false;

  constructor(protected translateService: TranslateService) {
    this.datePipe = new DatePipe(this.translateService.currentLang);
    this.subscription = this.translateService.onLangChange
      .asObservable()
      .subscribe((value) => {
        this.datePipe = new DatePipe(value.lang);
        this.markForChanges = true;
      });
  }

  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!this.hasChanges(value, format, timezone, locale)) {
      return this.result;
    }
    this.storeValues(value, format, timezone, locale);
    this.calcResult();
    this.markForChanges = false;
    return this.result;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected storeValues(value: any, format?: string, timezone?: string, locale?: string) {
    this.value = value;
    this.format = format;
    this.timezone = timezone;
    this.locale = locale;
  }

  protected hasChanges(value: any, format?: string, timezone?: string, locale?: string): boolean {
    return this.markForChanges || this.value !== value || this.format !== format || this.timezone !== timezone || this.locale !== locale;
  }

  protected calcResult() {
    this.result = this.datePipe.transform(this.value, this.format, this.timezone, this.locale);
  }
}
