import { ProjectStatusEnum } from '../../types/project-status.enum';

export class ChangeTaskStatusMessageModel {

  constructor(
    public body: string,
    public status: ProjectStatusEnum,
    public msgtype = 'm.task.change_status',
  ) { }

}
