import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export const birthdateValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const maxBirthDate = moment().subtract(18, 'years');
  const controlValue = moment(control.value);

  return maxBirthDate > controlValue ? null : {
    errorCode: 'birthdateValidationError',
  };
};
