import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

@Component({
  selector: 'tpt-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerHeaderComponent<Moment> implements OnDestroy {

  @HostBinding('class.tpt-datepicker-header')
  public baseClass = true;

  public get periodLabel(): any {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel);
  }

  private stateChangesSubscription: Subscription;

  constructor(
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private calendar: MatCalendar<Moment>,
    private dateAdapter: DateAdapter<Moment>,
    private cdf: ChangeDetectorRef
  ) {
    this.stateChangesSubscription = this.calendar.stateChanges.subscribe(this.cdf.markForCheck);
  }

  ngOnDestroy(): void {
    if (this.stateChangesSubscription) {
      this.stateChangesSubscription.unsubscribe();
      this.stateChangesSubscription = null;
    }
  }

  public previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate = mode === 'month'
      ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
      : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  public nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate = mode === 'month'
      ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
      : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

}
