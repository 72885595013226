import { ChangeTaskStatusMessageModel } from './change-task-status-message.model';
import { TextMessageModel } from './text-message.model';

export class SendTaskCommentsRequestModel {

  constructor(
    public taskId: string,
    public content: ChangeTaskStatusMessageModel | TextMessageModel,
  ) { }

}
