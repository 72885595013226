import { Pipe, PipeTransform } from '@angular/core';
import { isString, toNumber } from 'lodash';

@Pipe({
  name: 'tptEndings'
})
export class EndingsPipe implements PipeTransform {

  public transform(value: number, titles: Array<string>): string {
    const cases = [2, 0, 1, 1, 1, 2];
    if (isString(value)) {
      value = toNumber(value);
    }
    return titles[(value % 100 > 4 && value % 100 < 20) ? 2 : cases[(value % 10 < 5) ? value % 10 : 5]];
  }

}
