import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function digitLengthValidator(val: number): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const regex = `[0-9]{${val}}`;

    return new RegExp(regex).test(control.value) ? null : {
      validatePattern: {
        errorCode: 'exactLengthValidation',
        number: val
      }
    };
  };
}
