import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function inputMaxValidator(max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = Number(control.value);
    if (Number.isNaN(value)) {
      return {'value is not a number': {value: control.value}};
    } else {
      return value <= max ? null : {[`value > max(${max})`]: {value: control.value}};
    }
  };
}

@Directive({
  providers: [{
    multi: true,
    provide: NG_VALIDATORS,
    useExisting: InputMaxValidatorDirective,
  }],
  selector: '[xlnInputMax]',
})
export class InputMaxValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('max') public max: number;

  public validate(control: AbstractControl): { [key: string]: any } {
    return this.max ? inputMaxValidator(this.max)(control) : null;
  }
}
