<tpt-dialog>
  <div class="title mb-16">
    {{'SEARCH.LETS_ACQUAINTED' | translate}}
  </div>

  <div class="text">
    {{'SEARCH.LETS_ACQUAINTED_TEXT' | translate}}
  </div>

  <div class="actions-row">
    <tpt-button type="secondary" class="cancel mr-8" (onClick)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </tpt-button>
    <tpt-button type="primary" (onClick)="fillDetails()">
      {{'SEARCH.FILL_DETAILS' | translate}}
    </tpt-button>
  </div>
</tpt-dialog>
