import { Component } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-footer-light',
  templateUrl: './footer-light.component.html',
  styleUrls: ['./footer-light.component.scss']
})
export class FooterLightComponent {

  public svgIconsEnum = SvgIconsEnum;

  get year(): string {
    return new Date().getFullYear().toString();
  }

}
