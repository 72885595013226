import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[tptInputMin]'
})
export class InputMinValidatorDirective implements OnInit, OnDestroy {

  readonly destroy = new Subject();

  @Input()
  public min = 1;

  private keyPressSubscription: Subscription;

  constructor(
    @Optional() @Self() public input: ElementRef<HTMLInputElement>
  ) { }

  ngOnInit() {
    this.keyPressSubscription = fromEvent(this.input.nativeElement, 'keypress').pipe(
      takeUntil(this.destroy),
    ).subscribe(this.handleKeyPress);
    fromEvent(this.input.nativeElement, 'paste').subscribe(this.handlePaste);
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

  private handleKeyPress = (event: Event): void => {
    if (this.min > 0 && (event as any).key === '-') {
      event.preventDefault();
    }
  }

  private handlePaste = (event: Event): void => {
    const clipboardData = (event as any).clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.indexOf('-') !== -1) {
      event.preventDefault();
    }
  }

}
