import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerEventModel } from '../models/spinner-event.model';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public spinner$ = new BehaviorSubject<SpinnerEventModel>(new SpinnerEventModel(false));

  private defaultConfig = new SpinnerEventModel(true);

  public startSpinner = (config?: SpinnerEventModel): void => {
    if (config instanceof SpinnerEventModel) {
      this.spinner$.next(config);
    } else {
      this.spinner$.next(this.defaultConfig);
    }
  }

  public stopSpinner = (): void => {
    this.spinner$.next(new SpinnerEventModel(false));
  }

}
