<tpt-dialog>
  <div class="tpt-success-dialog-root">
    <mat-icon [svgIcon]="svgIconsEnum.DialogSuccessIcon"></mat-icon>

    <div class="success-phrase">
      <ng-content select="[mainText]"></ng-content>
    </div>

    <div class="secondary-phrase">
      <ng-content select="[secondaryText]"></ng-content>
    </div>

    <button mat-button class="close" (click)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </button>
  </div>
</tpt-dialog>
