import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { CategoryV2Model } from '../models/category-v2.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService {
  categories: CategoryV2Model[];

  constructor(http: HttpClient) {
    super(http);
  }

  public getCategoriesV2(): Observable<CategoryV2Model[]> {
    if (this.categories) {
      return of(this.categories);
    }
    return this.executeGet<CategoryV2Model[]>('v2/categories')
      .pipe(tap((res) => this.categories = res));
  }
}
