import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { TaskStatesEnum } from '../../../types/task-states.enum';
import { ProjectStatusEnum } from '../../employer/types/project-status.enum';

@Component({
  selector: 'tpt-task-state',
  templateUrl: './task-state.component.html',
  styleUrls: ['./task-state.component.scss']
})
export class TaskStateComponent {

  @Input() taskState: string;
  @Input() status: string;
  @Input() archived = false;

  public svgIconsEnum = SvgIconsEnum;
  public taskStatesEnum = TaskStatesEnum;
  public ProjectStatusEnum = ProjectStatusEnum;
}
