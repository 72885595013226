<div class="progress">

  <span
    class="progress-percentage"
    #progressPercentage
  >
    {{ progress }}%
  </span>

  <mat-progress-bar
    mode="determinate"
    [value]="progress"
  ></mat-progress-bar>

</div>
