import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-legal-info-dialog',
  templateUrl: './legal-info-dialog.component.html',
  styleUrls: ['./legal-info-dialog.component.scss']
})
export class LegalInfoDialogComponent {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() public changeStatus = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '720px',
  };

  private className = 'tpt-legal-info-dialog';

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }
}
