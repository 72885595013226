import { Moment } from 'moment';
import { moment } from './moment-helper';

export function objectsHaveDiff(a, b, exclude: string[] = []): boolean {
  const aPropCount = Object.keys(a).length;
  const bPropCount = Object.keys(b).length;

  if (aPropCount !== bPropCount) {
    return true;
  }

  for (const prop in a) {
    if (exclude.indexOf(prop) === -1) {
      if (b.hasOwnProperty(prop)) {
        if (a[prop] !== b[prop]) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function diffInDays(d1, d2): number {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function daysBetween(date1: Date, date2: Date) {
  /*const ONE_DAY = 1000 * 60 * 60 * 24;
  let difference_ms = Math.abs(date1.getTime() - date2.getTime());
  return Math.round(difference_ms / ONE_DAY)*/
  return Math.abs(diffInDays(date1, date2));
}

export function compareById(o1, o2): boolean {
  return o1 === o2 || (o1 && o2 && o1.hasOwnProperty('id') && o2.hasOwnProperty('id') && (o1.id === o2.id));
}

export function toMilles(date: Date | Moment): number {
  if (moment.isMoment(date)) {
    return date.valueOf();
  } else {
    return (date as Date).getTime();
  }
}
