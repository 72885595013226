import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'tpt-dialog-header',
  template: `
    <ng-content></ng-content>
  `
})
export class DialogHeaderComponent {

  @HostBinding('class.tpt-dialog-header')
  public baseClass = true;

}
