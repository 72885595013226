<tpt-dialog>
  <div class="wrapper" *ngIf="data">
    <div class="left">
      <div class="headline">
        Внесение предоплаты
      </div>

      <div class="freelancer-info mb-24 mt-48">
        <tpt-avatar [user]="data?.freelancer" [textSizeRatio]="3" class="mr-16"
                    size="50"></tpt-avatar>
        <div>
          <div class="subhead-1 mb-2">{{data?.freelancer.firstName}} {{data?.freelancer.lastName}}</div>
          <div class="caption">Java Developer</div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="caption mb-4 mt-24">Проект:</div>
      <div class="regular-14-20 mb-16">{{data?.projectInfo?.name}}</div>

      <div class="mb-28">
        <div class="caption row mb-4">
          <div>Детали:</div>
          <div>Стоимость:</div>
        </div>
        <div class="regular-14-20 row mb-4">
          <div class="">{{data?.projectInfo?.name}}</div>
          <div class="">{{data?.details?.budget}}{{currencySymbol[data.details?.currency?.code]}}</div>
        </div>
        <div class="regular-14-20 row mb-4">
          <div>Комиссия</div>
          <div>{{data?.details?.commissionValue}}{{currencySymbol[data.details?.currency?.code]}}</div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="total-budget mt-28">
        <div class="caption mb-8">Итого</div>
        <div class="headline-18-24">
          {{data?.details?.budgetWithCommission}}{{currencySymbol[data.details?.currency?.code]}}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="qr-code">
        <img [src]="getUrl(data?.qr)">
      </div>

      <div class="list">
        <div class="mb-16 list-item">
          <div class="number">1</div>
          <div class="regular-14-20">Откройте мобильное приложение вашего банка</div>
        </div>

        <div class="mb-16 list-item">
          <div class="number">2</div>
          <div class="regular-14-20">Отсканируйте QR-код</div>
        </div>

        <div class="list-item">
          <div class="number">3</div>
          <div class="regular-14-20">Подтвердите оплату</div>
        </div>
      </div>
    </div>
  </div>
</tpt-dialog>
