import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef } from '@angular/core';

import {
  isPresent,
  LoadingBarEvent,
  SlimLoadingBarEventType,
  LoadingBarService
} from '../../../../services/loading-bar.service';

@Component({
  selector: 'tpt-loading-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<div class="slim-loading-bar">
    <div class="slim-loading-bar-progress" [style.width]="progress + '%'" [style.backgroundColor]="color" [style.color]="color"
        [style.height]="height" [style.opacity]="show ? '1' : '0'" [style.transition]="isTransition"></div>
</div>`,
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit, AfterViewInit {
  @Input() set progress(progress: string) {
    this.isTransition = progress >= this._progress ?  'all 0.5s ease-in-out' : 'none';
    this._progress = progress;
  }

  get progress() {
    return this._progress;
  }

  isTransition = 'none';

  @Input() color = '#50997B';
  @Input() height = '2px';
  @Input() show = true;

  private _progress = '0';

  constructor(public service: LoadingBarService,
              private _elmRef: ElementRef,
              private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.service.events.subscribe((event: LoadingBarEvent) => {
      if (event.type === SlimLoadingBarEventType.PROGRESS && isPresent(event.value)) {
        this.progress = event.value;
      } else if (event.type === SlimLoadingBarEventType.COLOR) {
        this.color = event.value;
      } else if (event.type === SlimLoadingBarEventType.HEIGHT) {
        this.height = event.value;
      } else if (event.type === SlimLoadingBarEventType.VISIBLE) {
        this.show = event.value;
      }
    });
  }

  ngAfterViewInit(): void {
    this.service.events.subscribe((event: LoadingBarEvent) => {
      this._elmRef.nativeElement.visible = event.type === SlimLoadingBarEventType.VISIBLE ? event.value : true;
      this._changeDetectorRef.detectChanges();
    });
  }
}
