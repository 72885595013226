<tpt-dialog>
  <div class="heading">
    {{'TRACKER.OPEN_DISPUTE' | translate}}
  </div>
  <div class="description"
       [innerHTML]="(isEmployer ? 'TRACKER.OPEN_DISPUTE_TEXT_EMP' : 'TRACKER.OPEN_DISPUTE_TEXT_FR')| translate">
  </div>
  <mat-form-field floatLabel="never" appearance="outline">
    <textarea
      matInput
      class="textarea-control"
      [(ngModel)]="reason"
      [placeholder]="'TRACKER.DISPUTE_PLACEHOLDER' | translate"
    ></textarea>
  </mat-form-field>
  <div class="actions-row">
    <tpt-button type="secondary" class="cancel" (onClick)="close()">
      {{'BUTTON.CANCEL' | translate}}
    </tpt-button>
    <tpt-button type="primary" (onClick)="openDispute()" [disabled]="!reason.trim().length">
      {{'TRACKER.OPEN_DISPUTE' | translate}}
    </tpt-button>
  </div>
</tpt-dialog>
