import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type NotificationType = 'success' | 'fail';

interface Notification {
  type: NotificationType;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarNotificationsService {
  public notificationTimeout = 5000;
  private notificationSubject = new Subject<Notification>();

  constructor() {}

  public showNotification(message: string, type: NotificationType): void {
    this.notificationSubject.next({ message, type });
  }

  public getNotifications(): Observable<Notification> {
    return this.notificationSubject.asObservable();
  }

  public showSmthWentWrong(): void {
    this.showNotification('SNACKBAR.SOMETHING_WENT_WRONG', 'fail');
  }
}
