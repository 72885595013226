import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TaskCommentModel } from '../../../employer/models/task-comments/task-comment.model';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { TaskStatusesEnum } from '../../../../types/task_statuses.enum';

@Component({
  selector: 'emp-companion-message',
  templateUrl: './companion-message.component.html',
  styleUrls: [ './companion-message.component.scss' ],
})
export class CompanionMessageComponent {

  @Input()
  public message: TaskCommentModel;

  public statusEnum = TaskStatusesEnum;

  public svgIconsEnum = SvgIconsEnum;

  public get placeholderIcon(): string {
    return `/assets/icons/${SvgIconsEnum.AssigneePlaceholder}.svg`;
  }

  public get sendDate(): string {
    return moment(this.message.date).format('DD.MM.YYYY HH:mm');
  }

  public get isTextMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.text';
  }

  public get isChangeMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.change_status';
  }

  public get isDeclineMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.decline';
  }

  public get isOpenDisputeMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.open_dispute';
  }

  public get isAcceptMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.accept';
  }

  public get isStartArbitrageMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.start_arbitrage';
  }

  public get isReopenArbitrageMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.reopen_arbitrage';
  }

  public get isArbitrageDecisionMessage(): boolean {
    return this.message.content && this.message.content.msgtype === 'm.task.arbitrator_decision';
  }

  public getClassByStatus(status: TaskStatusesEnum): string {
    switch (status) {
      case TaskStatusesEnum.TODO:
        return '-todo';
      case TaskStatusesEnum.IN_PROGRESS:
        return '-in-progress';
      case TaskStatusesEnum.REVIEW:
        return '-review';
        case TaskStatusesEnum.HOLD:
        return '-hold';
        case TaskStatusesEnum.DONE:
        return '-done';
    }
  }
}
