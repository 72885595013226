import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-select-contract-dialog',
  templateUrl: './select-contract-dialog.component.html',
  styleUrls: ['./select-contract-dialog.component.scss']
})
export class SelectContractDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() selectContract = new EventEmitter();

  public contractType;

  private readonly className = 'app-select-contract-dialog';

  private readonly config: MatDialogConfig = {
    width: '600px',
  };

  constructor() { }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public submit(): void {
    if (!this.contractType) {
      return;
    }
    this.selectContract.emit(this.contractType);
    this.close();
  }
}
