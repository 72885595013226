import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-email-change-dialog',
  templateUrl: './email-change-dialog.component.html',
  styleUrls: ['./email-change-dialog.component.scss']
})
export class EmailChangeDialogComponent {

  @ViewChild(DialogComponent, { static: false })
  public dialog: DialogComponent;

  @Output() changeEmailEvent: EventEmitter<void> = new EventEmitter<void>();

  public currentEmail: string;

  public newEmail: string;

  private readonly className = 'tpt-email-change-dialog';

  private config: MatDialogConfig = {
    width: '620px',
  };

  public open(currentEmail: string, newEmail: string): void {
    this.currentEmail = currentEmail;
    this.newEmail = newEmail;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public changeEmail(): void {
    this.changeEmailEvent.emit();
    this.close();
  }

}
