import { TptControlDirective } from '../../directives/tpt-control.directive';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrorService } from '../../services/validation-error.service';

@Component({
  selector: 'tpt-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ],
})
export class SelectComponent extends TptControlDirective {

  @Input()
  public options: Array<{ id?: number | string, key?: any, code?: any, value?: string, nameRu?: string,
    name?: string, zoneId?: string, label?: string, disabled?: boolean }> = [];

  @Input()
  public placeholder = '';

  @Input()
  public disableOptionCentering: boolean;

  @Input()
  public panelClass = '';

  @Input()
  public multiple: boolean;

  @Input()
  public daysSelect: boolean;

  @Input()
  public needCustomTranslation = false;

  @Input()
  public showLabel = true;

  @Output()
  public selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.tpt-select')
  public baseClass = true;

  public lang: string;
  public transformedDays: string;

  constructor(protected validationErrorService: ValidationErrorService, private translate: TranslateService) {
    super(validationErrorService);

    this.lang = this.translate.currentLang;
    translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    });
  }

  @Input()
  public shouldDisplay(
    control: AbstractControl,
    option: { id?: number | string, key?: any, code?: any, value?: string, name?: string, zoneId?: string, label?: string },
    ...args: any
  ): boolean {
    return true;
  }
}
