import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidationErrorService } from '../services/validation-error.service';

@Directive({
  selector: '[tptControl]'
})
export class TptControlDirective {

  @Input()
  public control: AbstractControl = new UntypedFormControl(null);

  @Input()
  public placeholder = '';

  @Input()
  public hintAlign: 'start' | 'end' = 'end';

  @HostBinding('class.tpt-control')
  public baseClass = true;

  public get error(): string {
    return this.validationErrorService.getValidationErrorMessage(this.control);
  }

  public get errorState(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  constructor(
    protected validationErrorService: ValidationErrorService,
  ) { }

}
