import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormContext } from '../../tpt-forms/base/form.context';
import { IndividualEntrepreneurModel, SignerModel } from '../../../models/userLegalStatus.model';
import { digitLengthValidator } from '../../../validators/exact-length-digit-validator';

export class SignerForm extends FormContext<SignerModel> {

  static createForm(legalModel?: IndividualEntrepreneurModel): SignerForm {
    const legalStatus = legalModel.legalStatus;
    const form = new UntypedFormGroup({
      head: new UntypedFormGroup({
        firstName: new UntypedFormControl(legalModel?.head?.firstName || '',
          [Validators.required, Validators.maxLength(255),
            Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        lastName: new UntypedFormControl(legalModel?.head?.lastName || '',
          [Validators.required, Validators.maxLength(255),
            Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        patronymic: new UntypedFormControl(legalModel?.head?.patronymic || '',
          [Validators.maxLength(255), Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        document: new UntypedFormControl(legalModel?.head?.document, [Validators.required, Validators.maxLength(300)]),
        phone: new UntypedFormControl(legalModel?.head?.phone, [Validators.required]),
        email: new UntypedFormControl(legalModel?.head?.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
        ogrn: new UntypedFormControl(legalModel?.head?.ogrn, legalStatus === 'INDIVIDUAL_ENTREPRENEUR' ?
          [Validators.required, Validators.maxLength(15), digitLengthValidator(15)] : []),
        inn: new UntypedFormControl(legalModel?.head?.inn, legalStatus === 'INDIVIDUAL_ENTREPRENEUR' ?
          [Validators.required] : []), // digitLengthValidator(10), Validators.maxLength(10)
        fileIds: new UntypedFormControl(legalModel?.head?.files, [Validators.required]),
        position: new UntypedFormControl(legalModel?.head?.position,
          [Validators.required, Validators.maxLength(255), Validators.pattern('^[а-яА-ЯЁё"-\\s]+$')] )
      }),
      signer: new UntypedFormGroup({
        firstName: new UntypedFormControl(legalModel?.signer?.firstName || '',
          [Validators.required, Validators.maxLength(255),
            Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        lastName: new UntypedFormControl(legalModel?.signer?.lastName || '',
          [Validators.required, Validators.maxLength(255),
            Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        patronymic: new UntypedFormControl(legalModel?.signer?.patronymic || '',
          [Validators.maxLength(255), Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
        document: new UntypedFormControl(legalModel?.signer?.document, [Validators.required, Validators.maxLength(300)]),
        phone: new UntypedFormControl(legalModel?.signer?.phone, [Validators.required]),
        email: new UntypedFormControl(legalModel?.signer?.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
        fileIds: new UntypedFormControl(legalModel?.signer?.files, [Validators.required]),
        position: new UntypedFormControl(legalModel?.signer?.position, [Validators.required])
      }),
      headIsSigner: new UntypedFormControl(legalModel?.headIsSigner || false),
    });

    return new SignerForm(form);
  }

  public get head(): AbstractControl {
    return (this.form.get('head') as UntypedFormGroup);
  }

  public get signer(): AbstractControl {
    return (this.form.get('signer') as UntypedFormGroup);
  }

  public get headIsSigner(): AbstractControl {
    return this.form.get('headIsSigner');
  }

  constructor(
    public form: UntypedFormGroup,
  ) {
    super(form);
  }

  public getFormData(): SignerModel {
    return this.form.value;
  }

}
