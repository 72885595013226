import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { each } from 'lodash/fp';

export abstract class FormContext<T> {

  public static touchAll(control: AbstractControl): void {
    control.markAsTouched({ onlySelf: false });
    if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
      each(FormContext.touchAll, control.controls);
    }
  }

  public get touched(): boolean {
    return this.form.touched;
  }

  public get invalid(): boolean {
    return this.form.invalid;
  }

  protected constructor(
    protected form: UntypedFormGroup,
    protected data?: T
  ) { }

  public getControl(key: string): AbstractControl | null {
    return this.form.get(key);
  }

  public validate(): boolean {
    FormContext.touchAll(this.form);
    return this.form.valid;
  }

  public abstract getFormData(): T;

  public patchForm(data: Partial<T>): void {
    this.form.patchValue(data);
  }

}
