import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { ProjectSimpleTaskModel } from '../../employer/models/project-simple-task.model';
import { CategoryV2Model } from '../../../models/category-v2.model';

@Component({
  selector: 'emp-assignee-preview',
  templateUrl: './assignee-preview.component.html',
  styleUrls: [ './assignee-preview.component.scss' ],
})
export class AssigneePreviewComponent {

  @Input()
  public task: ProjectSimpleTaskModel;

  public svgIconsEnum = SvgIconsEnum;

  constructor(private translate: TranslateService) {
  }

  public getTranslation(item: CategoryV2Model): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getCategory(): string {
    if (this.task.categoryInfo.subCategory && this.task.categoryInfo.subCategory.id) {
      return this.getTranslation(this.task.categoryInfo.subCategory);
    } else {
      return this.getTranslation(this.task.categoryInfo.parentCategory);
    }
  }
}
