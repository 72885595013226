import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DadataService } from '../../../../services/dadata.service';
import { ValidationErrorService } from '../../../tpt-forms/services/validation-error.service';

@Component({
  selector: 'tpt-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit  {

  public get error(): string {
    return this.validationErrorService.getValidationErrorMessage(this.addressFormControl);
  }

  public get errorState(): boolean {
    return this.addressFormControl.invalid && (this.addressFormControl.dirty || this.addressFormControl.touched);
  }

  @Input() placeholder = '';
  @Input() addressFormControl = new UntypedFormControl();
  @Input() config = {};

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  data: any = [];

  constructor(private validationErrorService: ValidationErrorService,
              private dadataService: DadataService,
              ) { }

  ngOnInit(): void {
    this.addressFormControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (!val || val?.trim().length < 3) {
        return;
      }
      this.dadataService.suggestAddress({ query: val, ...this.config}).subscribe(res => {
        this.data = res.suggestions;
      });
    });
  }

  displayFn(address) {
    if (typeof address === 'string') {
      return address;
    }
    return address?.value;
  }
}
