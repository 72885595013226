import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let url = route.data.externalUrl;
    if (!environment.production && environment.currentFlow === 'dev') {
      url = `http://localhost:4201/${route.data.externalUrl}`;
    }
    window.location.href = url;
    return true;
  }
}
