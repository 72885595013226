import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import * as moment from 'moment';

@Component({
  selector: 'tpt-deadline-status',
  templateUrl: './deadline-status.component.html',
  styleUrls: ['./deadline-status.component.scss']
})
export class DeadlineStatusComponent {

  @Input()
  public deadlineDate: string;

  @Input()
  public primaryColor: boolean;

  @Input()
  public showLabel = false;

  public svgIconsEnum = SvgIconsEnum;

  public get isPrimary(): boolean {
    return this.primaryColor;
  }

  public get isOk(): boolean {
    return this.daysLeft > 9;
  }

  public get isWarn(): boolean {
    return this.daysLeft > 3 && this.daysLeft < 7;
  }

  public get isErr(): boolean {
    return this.daysLeft <= 3;
  }

  public get daysLeft(): number {
    return moment(this.deadlineDate).diff(moment(), 'days');
  }

}
