import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'tpt-confirm-destruction-dialog',
  templateUrl: './confirm-destruction-dialog.component.html',
  styleUrls: ['./confirm-destruction-dialog.component.scss']
})
export class ConfirmDestructionDialogComponent {

  public title: string;

  public description: string;

  public action: string;

  public type: string;

  public buttonType = 'warn';

  @Output()
  public readonly actionDecline = new EventEmitter<string>();

  @Output()
  public readonly actionApprove = new EventEmitter<string>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private readonly className = 'emp-delete-dialog';

  private config: MatDialogConfig = {
    width: '620px',
  };

  public open(type: string, title: string, description: string, buttonType: string, action: string): void {
    this.type = type;
    this.title = title;
    this.description = description;
    this.buttonType = buttonType;
    this.action = action;
    if (type === 'DELETE_PARTICIPANT') {
      this.config.panelClass = 'chat-dialog-align';
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public declineAction(): void {
    this.actionDecline.emit(this.type);
    this.close();
  }

  public approveAction(): void {
    this.actionApprove.emit(this.type);
    this.close();
  }
}
