<tpt-dialog>
  <div class="heading">
    {{'TRACKER.CHALLENGE_DECISION' | translate}}
  </div>
  <div class="description"
       [innerHTML]="'TRACKER.CHALLENGE_DECISION_TEXT' | translate">
  </div>
  <div class="label">{{'TRACKER.CHALLENGE_DECISION_LABEL' | translate}}*</div>
  <mat-form-field floatLabel="never" appearance="outline">
    <textarea
      matInput
      class="textarea-control"
      [(ngModel)]="reason"
      [placeholder]="'TRACKER.CHALLENGE_DECISION_PLACEHOLDER' | translate"
    ></textarea>
  </mat-form-field>
  <div class="actions-row">
    <tpt-button type="secondary" class="cancel" (onClick)="close()">
      {{'BUTTON.CANCEL' | translate}}
    </tpt-button>
    <tpt-button type="primary" (onClick)="challengeDecision()" [disabled]="!reason.trim().length">
      {{'TRACKER.CHALLENGE_DECISION' | translate}}
    </tpt-button>
  </div>
</tpt-dialog>
