import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {

  public svgIconsEnum = SvgIconsEnum;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type: 'success' | 'fail';
      message: string;
    },
    private snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  get isSuccess(): boolean {
    return this.data.type === 'success';
  }

  get isFail(): boolean {
    return this.data.type === 'fail';
  }

  get message(): string {
    return this.data.message;
  }

  public closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
