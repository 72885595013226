import { Component, OnInit } from '@angular/core';
import { RouteHelper } from '../../../../../helpers/route.helper';

@Component({
  selector: 'tpt-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent {

  constructor(
    public routeHelper: RouteHelper,
  ) { }

}
