<div class="note" [class.-active]="activeNote">
  <div class="note-header">
    <span class="time">
      {{ noteCreateTime }}
    </span>
    <div class="d-flex align-items-center">
<!--      <mat-icon-->
<!--        *ngIf="note.pinned"-->
<!--        [svgIcon]="svgIconsEnum.Unpin"-->
<!--        (click)="$event.stopPropagation(); unpinNote.emit(note)"-->
<!--        class="mr-8 pin-icon"-->
<!--      >-->
<!--      </mat-icon>-->
<!--      <mat-icon-->
<!--        *ngIf="!note.pinned"-->
<!--        [svgIcon]="svgIconsEnum.Pin"-->
<!--        (click)="$event.stopPropagation(); pinNote.emit(note)"-->
<!--        class="mr-8 pin-icon"-->
<!--      >-->
<!--      </mat-icon>-->
      <mat-icon
        *ngIf="activeNote"
        [svgIcon]="svgIconsEnum.Cross"
        (click)="deleteNote.emit(note)"
      ></mat-icon>
    </div>
  </div>
  <div class="note-text">
    {{ note?.text | tptTruncate: [109] }}
  </div>
</div>
