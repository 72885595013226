import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {PortalService} from '../../services/portal.service';
import {TemplatePortal} from '@angular/cdk/portal';
import { ProfileService } from '../../services/profile.service';
import { RouteHelper } from '../../helpers/route.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'search-page',
  templateUrl: './search.component.html',
})
export class SearchComponent implements AfterViewInit, OnDestroy {

  @ViewChild('headerPageTitle', { static: true })
  public headerPageTitleTemplateRef: TemplateRef<any>;

  constructor(
    private vcr: ViewContainerRef,
    private portalService: PortalService,
    private profile: ProfileService,
    private router: Router,
    private routeHelper: RouteHelper
  ) {
  }

  public ngAfterViewInit(): void {
    this.portalService.attachToHeaderPortal(new TemplatePortal<any>(this.headerPageTitleTemplateRef, this.vcr));
    if (this.router.url === '/search') {
      this.router.navigate(this.isEmployer ? this.routeHelper.freelancerSearch : this.routeHelper.projectSearch);
    }
  }

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  ngOnDestroy(): void {
    this.portalService.detachHeaderPortal();
  }

}
