import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IDateRange } from '../../../classes/iDateRange';
import { moment } from '../../../moment-helper';
import { LocalDatePipe } from './local-date.pipe';

@Pipe({
  name: 'dateRange',
  pure: false, // required to update the value when the promise is resolved
})
export class DateRangePipe extends LocalDatePipe implements PipeTransform {
  private startDate: Date = null;
  private tillDate: Date = null;
  private localTimeZone: string = moment.tz.guess();

  public transform(value: IDateRange, format?: string, timezone?: string, locale?: string): string | null {
    return super.transform(value, format, timezone, locale);
  }

  public storeValues(value: IDateRange, format?: string, timezone?: string, locale?: string) {
    super.storeValues(value, format, timezone, locale);
    this.tillDate = value.tillDate;
    this.startDate = value.startDate;
  }

  public hasChanges(value: IDateRange, format?: string, timezone?: string, locale?: string): boolean {
    const changes = super.hasChanges(value, format, timezone, locale);
    return changes || this.startDate !== value.startDate || this.tillDate !== value.tillDate;
  }

  public calcResult() {
    let time = '';
    if (this.timezone && this.timezone !== this.localTimeZone) {
      time = ' ' + getLocaleTimeFormat(this.translateService.currentLang, FormatWidth.Short);
    }
    const baseFormat = getLocaleDateFormat(this.translateService.currentLang, FormatWidth.Medium) + time;
    let fromFormat = baseFormat;
    if (this.tillDate && this.startDate.getFullYear() === this.tillDate.getFullYear()) {
      fromFormat = fromFormat.replace(/[^Md]*y+[^Md]*/, '') + time;
    }
    this.result = `${this.datePipe.transform(this.startDate, fromFormat)} \u2014 ${this.tillDate
      ? this.datePipe.transform(this.tillDate, baseFormat) : ''}`;
  }
}
