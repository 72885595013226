
export enum LanguageLevelEnum {

  BEGINNER = 'Начальный',

  MEDIUM = 'Средний',

  ADVANCED = 'Продвинутый',

  NATIVE = 'Родной',

}
