import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

const MIN = 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30.416;

@Pipe({
  name: 'timeAgo',
  pure: false, // required to update the value when the promise is resolved
})

export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;
  private changeLangSubscription: Subscription;
  private value: string = null;
  private result: string = null;
  private timer = null;
  private text: string = null;
  private val = 0;
  private timeToUpdate: number;
  private dateVal: Date = null;

  constructor(private translateService: TranslateService,
              private cd: ChangeDetectorRef,
              private ngZone: NgZone) {
    this.changeLangSubscription = translateService.onLangChange
      .subscribe((value) => {
        this.translate(this.val, this.text);
      });
  }

  public transform(value: string, ...args): string | null {
    if (this.value !== value) {
      this.value = value;
      this.dateVal = new Date(this.value);
      this.removeTimer();
      this.calcResult();
    } else {
      this.createTimer();
    }
    return this.result;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.changeLangSubscription) {
      this.changeLangSubscription.unsubscribe();
    }
    this.removeTimer();
  }

  private calcResult() {
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - this.dateVal.getTime()) / 1000));
    this.timeToUpdate = ((Number.isNaN(seconds)) ? 1 : this.getSecondsUntilUpdate(seconds)) * 1000;
    this.createTimer();
    const minutes = Math.floor(seconds / MIN);
    const hours = Math.floor(seconds / HOUR);
    const days = Math.floor(seconds / DAY);
    const months = Math.floor(seconds / MONTH);
    const years = Math.floor(days / 365);
    if (Number.isNaN(seconds)) {
      // return previous result
    } else if (minutes <= 0) {
      this.translate(0, 'TIME_AGO.FEW_SECONDS');
    } else if (hours <= 0) {
      this.translate(minutes, 'TIME_AGO.MINUTES');
    } else if (days <= 0) {
      this.translate(hours, 'TIME_AGO.HOURS');
    } else if (months <= 0) {
      this.translate(days, 'TIME_AGO.DAYS');
    } else if (years <= 0) {
      this.translate(months, 'TIME_AGO.MONTHS');
    } else {
      this.translate(years, 'TIME_AGO.YEARS');
    }
  }

  private createTimer() {
    if (this.timer) {
      return;
    }
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.timer = null;
          this.calcResult();
        }, this.timeToUpdate);
      } else {
        return null;
      }
    });
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
    this.timer = null;
  }

  private translate(value: number, text: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.val = value;
    this.text = text;
    this.subscription = this.translateService.get(text, {count: value})
      .subscribe((result) => {
        this.result = result;
        this.ngZone.run(() => this.cd.markForCheck());
      });
  }

  private getSecondsUntilUpdate(seconds: number) {
    if (seconds < HOUR) { // less than 1 min, update every 30 secs
      return MIN / 2;
    } else if (seconds < DAY) { // less than an hour, update every 30 min
      return HOUR / 2;
    } else if (seconds < MONTH) { // less then a day, update every 12 hour
      return DAY / 2;
    } else { // update every half month
      return MONTH / 2;
    }
  }
}
