<tpt-dialog>
  <div class="dialog">
    <div class="dialog-title">{{ 'SETTINGS.EMAIL_CHANGE_TITLE' | translate}}</div>
    <div class="dialog-description">
      <div>{{'SETTINGS.CURRENT_EMAIL' | translate}} — <span class="email">{{currentEmail}}</span></div>
      <div>{{'SETTINGS.NEW_EMAIL' | translate}} — <span class="email">{{newEmail}}</span></div>
      {{'SETTINGS.EMAIL_CHANGE_TEXT' | translate}}
    </div>

    <div class="dialog-actions">
      <tpt-button
        type="secondary"
        (click)="close()"
      >
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button
        type="primary"
        class="ml-12"
        (click)="changeEmail()"
      >
        {{'SETTINGS.CHANGE_EMAIL' | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
