import { FileUpload } from '../../../../models/fileUpload';

export class TextMessageModel {

  public files: Array<FileUpload>;

  constructor(
    public body: string,
    public fileIds: Array<FileUpload> = [],
    public msgtype = 'm.text',
  ) { }

}
