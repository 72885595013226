import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'open-dispute-dialog',
  templateUrl: './open-dispute-dialog.component.html',
  styleUrls: ['./open-dispute-dialog.component.scss']
})
export class OpenDisputeDialogComponent {
  @Output()
  public readonly openDisputeEvent = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public reason = '';

  private className = 'open-dispute-dialog';

  private config: MatDialogConfig = {
    width: '460px',
  };

  constructor(private profile: ProfileService) {
  }

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public openDispute(): void {
    if (!this.reason.trim()) { return; }
    this.openDisputeEvent.emit(this.reason);
    this.reason = '';
    this.close();
  }

}
