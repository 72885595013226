<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="title" *ngIf="title">{{title | translate}}</div>

    <div class="message" *ngIf="message">{{message | translate}}</div>

    <tpt-button *ngIf="!showSendEmailBtn" type="secondary" size="large" class="secondary-btn" (onClick)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </tpt-button>
    <tpt-button *ngIf="showSendEmailBtn" type="primary" size="large" (onClick)="sendEmail()">
      {{'BUTTON.SEND_CONFIRM' | translate}}
    </tpt-button>
  </div>
</tpt-dialog>
