import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ProfileService } from '../../../../services/profile.service';
import { RouteHelper } from '../../../../helpers/route.helper';

@Component({
  selector: 'job-invite-error-dialog',
  templateUrl: './job-invite-error-dialog.component.html',
  styleUrls: ['./job-invite-error-dialog.component.scss']
})
export class JobInviteErrorDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public selfEmployedStatus: string;

  @Input()
  public isInvite = false;

  @Output()
  public closeEvent = new EventEmitter<void>();

  private readonly className = 'job-invite-error-dialog';

  private readonly config: MatDialogConfig = {
    width: '460px',
    minHeight: '316px'
  };

  constructor(private profileService: ProfileService,
              public routeHelper: RouteHelper) {
  }

  public open(): void {
    this.selfEmployedStatus = this.profileService.currentProfile.selfEmployedStatus;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public fillProfile(): void {
    this.close();
    this.closeEvent.emit();
  }
}
