import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ProfileModel } from '../../../models/profile.model';

export class TptValidators {

  static passwordConfirmation = (passCtrl: string, confirmCtrl: string) => (ctrl: AbstractControl): ValidationErrors | null => {
    if (!ctrl.root || !ctrl.parent) {
      return null;
    }

    const pass = ctrl.parent.get(passCtrl).value;
    const confirmPass = ctrl.parent.get(confirmCtrl).value;

    if (pass !== confirmPass) {
      return { passwordConfirmation: true };
    }

    return null;
  }

  static passwordLettersDigits = (passCtrl: string) => (ctrl: AbstractControl): ValidationErrors | null => {
    if (!ctrl.root || !ctrl.parent) {
      return null;
    }

    const pass = ctrl.parent.get(passCtrl).value;
    const regex = '(?=.*\\d)(?=.*[a-zA-Zа-яА-Я]).{8,}';

    return new RegExp(regex).test(pass) ? null : {
      passwordLettersDigits: true,
    };
  }

  static passwordValidator = (passCtrl: string) => (ctrl: AbstractControl): ValidationErrors | null => {
    if (!ctrl.root || !ctrl.parent) {
      return null;
    }

    const pass = ctrl.parent.get(passCtrl).value;
    const regex = '(?=.*?[a-z])(?=.*?[A-Z])(?=.*\\d)(?=.*?[" !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~"])';

    return new RegExp(regex).test(pass) ? null : {
      passwordValidator: true,
    };
  }

  static sameEmail = (emailCtrl: string, email: string) => (ctrl: AbstractControl): ValidationErrors | null => {
    if (!ctrl.root || !ctrl.parent) {
      return null;
    }

    const newEmail = ctrl.parent.get(emailCtrl).value;

    return newEmail?.toLowerCase() === email?.toLowerCase() ? {
      sameEmail: true,
    } : null;
  }

  static safePassword = (passCtrl: string, profile: ProfileModel) => (ctrl: AbstractControl): ValidationErrors | null => {
    if (!ctrl.root || !ctrl.parent || !profile) {
      return null;
    }

    const email = profile.email.toLowerCase();
    const phone = profile.phone ? profile.phone.toLowerCase() : null;
    const firstName = profile.firstName ? profile.firstName.toLowerCase() : null;
    const lastName = profile.lastName ? profile.lastName.toLowerCase() : null;
    const middleName = profile.patronymic ? profile.patronymic.toLowerCase() : null;

    const pass = ctrl.parent.get(passCtrl).value.toLowerCase();

    if ([email, phone, firstName, lastName, middleName, `${firstName}${lastName}`, `${lastName}${firstName}`]
      .includes(pass)) {
      return {
        safePassword: true
      };
    }

    return null;
  }
}
