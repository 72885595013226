import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { CommonShareModule } from '../common-share.module';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';

@NgModule({
  declarations: [ChipsAutocompleteComponent],
  imports: [
    CommonModule,
    CommonShareModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [
    ChipsAutocompleteComponent,
    MatChipsModule,
    MatAutocompleteModule,
  ]
})
export class ChipsAutocompleteModule {
}
