import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService extends BaseService {
  constructor(http: HttpClient) {
    super(http);

    this.baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/api/v2/users/`;
  }

  public forgotPassword(username: string, service: string = 'TALENT_CLOUD'): Promise<void> {
    return this.executePost<void>('forgotPassword', username, { params: { service } });
  }

  public changePassword(token: string, newPassword: string, newPasswordConfirm: string): Promise<void> {
    return this.executePost<void>('restorePassword', {
      token,
      newPassword,
      newPasswordConfirm,
    });
  }
}
