import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'chat-info-dialog',
  templateUrl: './chat-info-dialog.component.html',
  styleUrls: ['./chat-info-dialog.component.scss']
})
export class ChatInfoDialogComponent {

  @Output() editChat: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public chat;

  public currentUser;

  private config: MatDialogConfig = {
    width: '580px',
    panelClass: 'chat-dialog-align',
  };

  private className = 'chat-info-dialog';

  constructor(private translate: TranslateService, private profileService: ProfileService) {
  }

  public open(chat): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.chat = chat;
    const id = this.profileService.currentProfile.id;
    this.currentUser = this.chat.participants.filter(item => item.participant.id === id)[0];
  }

  public close(): void {
    this.dialog.close();
  }

}
