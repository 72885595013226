import { Pipe, PipeTransform } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { ResourcesService } from '../../../services/resources.service';

@Pipe({
  name: 'tptAvatar'
})
export class AvatarPipe implements PipeTransform {

  constructor(private resource: ResourcesService) {
  }

  public transform(fileId: string | null, ...args): string {
    if (!fileId) {
      if (args[0] === 'empty') {
        return `/assets/icons/${SvgIconsEnum.AssigneePlaceholder}.svg`;
      }

      return null;
    }

    return this.resource.getUrl(fileId);
  }

}
