<tpt-dialog>
  <div class="dialog">
    <div class="dialog-title" *ngIf="title">{{ title | translate}}</div>
    <div *ngIf="text" class="dialog-description">{{ text | translate}}</div>

    <div class="dialog-actions">
      <tpt-button
        type="primary"
        (click)="close()"
      >
        {{'BUTTON.CLOSE' | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
