import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormContext } from '../../tpt-forms/base/form.context';
import { LegalEntityModel, RequisitesModel } from '../../../models/userLegalStatus.model';
import { digitLengthValidator } from '../../../validators/exact-length-digit-validator';

export class ForeignAgencyRequisitesForm extends FormContext<RequisitesModel> {

  static createForm(legalEntity?: LegalEntityModel): ForeignAgencyRequisitesForm {
    const form = new UntypedFormGroup({
      companyName: new UntypedFormControl(legalEntity.companyName || '', [Validators.required]),
      fullCompanyName: new UntypedFormControl(legalEntity.fullCompanyName || '', [Validators.required]),
      inn: new UntypedFormControl(legalEntity.inn || '', [Validators.required, digitLengthValidator(10), Validators.maxLength(10)]),
      regNumber: new UntypedFormControl(legalEntity.regNumber || '', [Validators.required]),
      taxNumber: new UntypedFormControl(legalEntity.taxNumber || '', [Validators.required]),
      phone: new UntypedFormControl(legalEntity.phone, [Validators.required]),
      email: new UntypedFormControl(legalEntity.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
      taxInvoiceWayToGet: new UntypedFormControl(legalEntity?.taxInvoiceWayToGet || '', [Validators.required]),
      postAddressType: new UntypedFormControl('SEPARATE'),
      postalAddress: new UntypedFormGroup({
        flatAddress: new UntypedFormControl(legalEntity?.postalAddress?.flatAddress || '',
          legalEntity?.taxInvoiceWayToGet === 'RUSSIAN_POST' ? [Validators.required] : []),
      }),
    });

    return new ForeignAgencyRequisitesForm(form);
  }

  public get companyName(): AbstractControl {
    return this.getControl('companyName');
  }

  public get fullCompanyName(): AbstractControl {
    return this.getControl('fullCompanyName');
  }

  public get inn(): AbstractControl {
    return this.getControl('inn');
  }

  public get regNumber(): AbstractControl {
    return this.getControl('regNumber');
  }

  public get taxNumber(): AbstractControl {
    return this.getControl('taxNumber');
  }

  public get phone(): AbstractControl {
    return this.getControl('phone');
  }

  public get email(): AbstractControl {
    return this.getControl('email');
  }

  public get taxInvoiceWayToGet(): AbstractControl {
    return this.getControl('taxInvoiceWayToGet');
  }

  public get postalAddress(): AbstractControl {
    return (this.form.get('postalAddress') as UntypedFormGroup).get('flatAddress');
  }

  constructor(
    public form: UntypedFormGroup,
  ) {
    super(form);
  }

  public getFormData(): RequisitesModel {
    return this.form.value;
  }

}
