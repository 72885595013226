export enum EmployerLegalStatusEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  FOREIGN_AGENCY = 'FOREIGN_AGENCY',
}

export enum FreelancerLegalStatusEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
}

export enum SelfEmployedStatusEnum {
  REMOVED_FROM_REGISTER = 'Снят с учёта',
  ACTIVE = 'Самозанятый',
  NOT_VERIFIED = 'Не верифицирован',
  DETACHED = 'Отключен от платформы',
}
