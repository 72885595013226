import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SkillV2Model } from '@/models/skill-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SkillService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getSkillsV2(): Observable<SkillV2Model[]> {
    return this.executeGet<SkillV2Model[]>('v2/skills');
  }

  public getCategorySkillsV2(categoryId: string, prefix?: string): Observable<SkillV2Model[]> {
    const params = { prefix, categoryId };
    if (!params.prefix) {
      delete params.prefix;
    }

    if (!params.categoryId) {
      delete params.categoryId;
    }
    return this.executeGet<SkillV2Model[]>('v2/skills', { params });
  }

  public addCustomSkillV2 = (prefix: string, selected?: Array<number>, categoryId?: number): Promise<SkillV2Model> => {
    const params = { prefix, selected, categoryId };
    if (!params.selected) {
      delete params.selected;
    }

    if (!params.categoryId) {
      delete params.categoryId;
    }

    return lastValueFrom(this.executeGet<SkillV2Model>('v2/skills', { params }));
  }
}
