<ng-container *ngIf="!user?.avatar && (!user?.firstName || !user?.lastName); else avatar">
  <mat-icon [svgIcon]="svgIconsEnum.AssigneePlaceholder"
            [ngStyle]="{'width': size+'px', 'height': size+'px'}"></mat-icon>
</ng-container>

<ng-template #avatar>
  <ngx-avatars [textSizeRatio]="textSizeRatio"
              [size]="size"
              [src]="user.avatar | tptAvatar"
              [bgColor]="bgColor"
              [fgColor]="fgColor"
              name="{{user.firstName}} {{user?.lastName}}">
  </ngx-avatars>
</ng-template>

