import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ContactForm } from '../../../forms/contact.form';
import { MainPageService } from '../../../services/main-page.service';
import { ValidationErrorService } from '../../tpt-forms/services/validation-error.service';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-contact-us-mobile',
  templateUrl: './contact-us-mobile.component.html',
  styleUrls: ['./contact-us-mobile.component.scss']
})
export class ContactUsMobileComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;
  public form: ContactForm;
  public loading = false;
  public acceptTerms = false;
  public showSuccess = false;
  public acceptPrivacy: boolean;
  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '100vw',
    height: '100vh',
    panelClass: 'contact-mobile-dialog',
    position: {bottom: '0'}
  };

  private className = 'tpt-contact-us-mobile';

  constructor(private feedbackService: MainPageService,
              private validationService: ValidationErrorService) { }

  ngOnInit() {
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.form = ContactForm.createForm();
  }

  public close(): void {
    this.dialog.close();
  }

  public get nameIsInvalid(): boolean {
    return this.form.firstName.invalid &&
      this.form.firstName.dirty ||
      this.form.firstName.touched;
  }

  public get nameValidationError(): string {
    return this.validationService.getValidationErrorMessage(this.form.firstName);
  }

  public get lastNameIsInvalid(): boolean {
    return this.form.lastName.invalid &&
      this.form.lastName.dirty ||
      this.form.lastName.touched;
  }

  public get lastNameValidationError(): string {
    return this.validationService.getValidationErrorMessage(this.form.lastName);
  }

  public get emailIsInvalid(): boolean {
    return this.form.email.invalid &&
      this.form.email.dirty ||
      this.form.email.touched;
  }

  public get emailValidationError(): string {
    return this.validationService.getValidationErrorMessage(this.form.email);
  }

  public get messageIsInvalid(): boolean {
    return this.form.message.invalid &&
      this.form.message.dirty ||
      this.form.message.touched;
  }

  public get messageValidationError(): string {
    return this.validationService.getValidationErrorMessage(this.form.message);
  }

  public submit(): void {
    this.form.validate();
    if (this.form.invalid) { return; }

    this.loading = true;
    this.feedbackService.contactUs(this.form.getFormData()).subscribe(
      () => {
        this.showSuccess = true;
        this.loading = false;
      },
      (() => {
        this.loading = false;
      })
    );
  }
}
