import { Component, Input } from '@angular/core';
import { FileUpload } from '@/models/fileUpload';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ResourcesService } from '@/services/resources.service';

@Component({
  selector: 'tpt-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: [ './uploaded-file.component.scss' ]
})
export class UploadedFileComponent {

  @Input()
  public file: FileUpload;

  public svgIconsEnum = SvgIconsEnum;

  constructor(
    public resourcesService: ResourcesService,
  ) { }

  public getFile(file: FileUpload): void {
    if (!this.file) {
      return;
    }

    this.resourcesService.getFileByUUID(file.id).subscribe(this.handleFile);
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resourcesService.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }

}
