import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    DialogComponent,
    DialogHeaderComponent,
  ],
  exports: [
    DialogComponent,
    DialogHeaderComponent,
  ]
})
export class DialogModule {

}
