import { Component, HostListener } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { isMobileDevice } from '@/helpers/get-mobile.helper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tpt-header-light',
  templateUrl: './header-light.component.html',
  styleUrls: ['./header-light.component.scss']
})
export class HeaderLightComponent {
  public svgIconsEnum = SvgIconsEnum;
  public isMobile = isMobileDevice();

  constructor(public activatedRoute: ActivatedRoute) {
  }

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }
}
