<tpt-dialog>
  <div class="wrapper">
    <div class="title">{{'TRACKER.CHARACTERISTICS' | translate}}</div>

    <div *ngFor="let metric of metrics">
      <div class="subhead">{{'TRACKER.'+metric | translate}}</div>
      <div class="description">{{'TRACKER.'+metric+'_DESCRIPTION' | translate}}</div>
    </div>
  </div>
</tpt-dialog>
