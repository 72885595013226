import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'tpt-rating-metrics-dialog',
  templateUrl: './rating-metrics-dialog.component.html',
  styleUrls: ['./rating-metrics-dialog.component.scss']
})
export class RatingMetricsDialogComponent {

  public metrics = [
    'WORK_QUALITY',
    'RAPIDITY',
    'QUALIFY',
    'ADEQUACY'
  ];

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private config: MatDialogConfig = {
    width: '504px',
  };

  private className = 'tpt-rating-metrics-dialog';

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
