<div
  class="days-remaining"
  [class.-primary]="isPrimary"
  [class.-success]="isOk"
  [class.-warn]="isWarn"
  [class.-err]="isErr"
>
  <mat-icon [svgIcon]="svgIconsEnum.Clock"></mat-icon>
  <div class="days-label">
    <ng-container *ngIf="showLabel">{{'PROJECTS.DEADLINE' | translate}}:</ng-container>
    {{ deadlineDate | localDate: 'dd.MM' }}
  </div>
</div>
