import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TptControlDirective } from '../../directives/tpt-control.directive';

@Component({
  selector: 'tpt-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: [ './textarea.component.scss' ],
})
export class TextareaComponent extends TptControlDirective {

  @Output()
  public onKeyup = new EventEmitter();

  @Input()showLabel = true;

  @HostBinding('class.tpt-textarea')
  public baseClass = true;

  setHeight(height: number): void {
    document.getElementById('textarea').style.height = height + 'px';
  }
}
