import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import * as moment from 'moment';

@Component({
  selector: 'xln-job-card-days-remaining',
  templateUrl: './job-card-days-remaining.component.html',
  styleUrls: [ './job-card-days-remaining.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobCardDaysRemainingComponent {

  @Input()
  public deadlineDate: string;

  @Input()
  public primaryColor: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public get isPrimary(): boolean {
    return this.primaryColor;
  }

  public get isSuccess(): boolean {
    return this.daysLeft > 3;
  }

  public get isWarn(): boolean {
    return false;
  }

  public get isErr(): boolean {
    return this.daysLeft <= 3;
  }

  public get daysLeft(): number {
    return moment(this.deadlineDate).diff(moment(), 'days');
  }

}
