<div class="state-block">
  <div *ngIf="taskState === taskStatesEnum.InDispute">
    <mat-icon [svgIcon]="svgIconsEnum.Bubble" class="red-icon"></mat-icon>
    {{'TRACKER.DISPUTE' | translate}}
  </div>

  <div *ngIf="taskState === taskStatesEnum.DeclineByOwner || taskState === taskStatesEnum.DeclineByFreelancer">
    <mat-icon [svgIcon]="svgIconsEnum.Cancel"></mat-icon>
    {{'TRACKER.DECLINED' | translate}}
  </div>

  <div *ngIf="taskState === taskStatesEnum.Arbitrage || taskState === taskStatesEnum.ArbitrageDecisionWasMade">
    <mat-icon [svgIcon]="svgIconsEnum.Mustache"></mat-icon>
    {{'TRACKER.ARBITRAGE' | translate}}
  </div>

  <div *ngIf="archived" class="wait-status">
    <mat-icon [svgIcon]="svgIconsEnum.Archived"></mat-icon>
    {{'TRACKER.ARCHIVED' | translate}}
  </div>

  <div *ngIf="(taskState !== taskStatesEnum.AcceptByFreelancer && status === ProjectStatusEnum.ToDo) && !archived"
       class="wait-status">
    <mat-icon [svgIcon]="svgIconsEnum.Zzz"></mat-icon>
    {{'TRACKER.AWAITING' | translate}}
  </div>
</div>
