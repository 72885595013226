import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { TptControlDirective } from '../../directives/tpt-control.directive';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { DatepickerHeaderComponent } from '../datepicker-header/datepicker-header.component';

@Component({
  selector: 'tpt-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: [ './datepicker.component.scss' ],
})
export class DatepickerComponent extends TptControlDirective {

  @Input()
  public min: Moment;

  @Input()
  public showLabel = true;

  @Input() public appearance: MatFormFieldAppearance | 'none' = 'none';

  @Input()
  public max: Moment;

  @Input()
  public arrowIcon = false;

  @Input()
  public panelClass = '';

  @Input()
  public placeholder = moment().format('DD.MM.YYYY');

  @ViewChild(MatDatepicker, { static: true })
  public datepicker: MatDatepicker<Moment>;

  @HostBinding('class.tpt-datepicker')
  public baseClass = true;

  public svgIconsEnum = SvgIconsEnum;

  public datepickerHeader = DatepickerHeaderComponent;

  public dateFilterFn = (date: Moment | null): boolean => {
    if (this.min && this.max) {
      return date >= this.min && date <= this.max;
    }

    if (this.min) {
      return date >= this.min;
    }

    if (this.max) {
      return date <= this.max;
    }

    return true;
  }

}
