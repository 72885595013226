import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-tooltip-button',
  template: `
    <div
      class="tooltip"
      [matTooltip]="tooltipText"
      [matTooltipClass]="tooltipClass"
      [matTooltipPosition]="position"
    >
      <mat-icon [svgIcon]="svgIconsEnum.Info"></mat-icon>
    </div>
  `,
  styleUrls: [ './tooltip-button.component.scss' ],
})
export class TooltipButtonComponent {

  @Input()
  public tooltipText: string;

  @Input()
  public position = 'right';

  @Input()
  public tooltipClass: string | Array<string> = ['tooltip-styling', 'tooltip-break-line'];

  public svgIconsEnum = SvgIconsEnum;

}
