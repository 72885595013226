<div class="card-wrapper" *ngIf="feedback" (click)="clickReview.emit()">
  <div class="header-row mb-16">
    <div class="title">
      {{feedback.jobInfo?.jobName}}
    </div>
    <mat-icon [svgIcon]="svgIconsEnum.ArrowRight"></mat-icon>
  </div>

  <div class="main-rating">
    <div class="main-rating-text">{{'SETTINGS.rating' | translate}}</div>
    <span class="main-rating-value">{{feedback?.generalAssessment | percent}}</span>
  </div>
  <div class="progress-row">
    <div class="progress-container">
      <mat-progress-bar [value]="feedback?.generalAssessment * 100" color="primary" mode="determinate"></mat-progress-bar>
    </div>
  </div>

  <div class="footer-row mt-20">
    <div class="sender">
      <tpt-avatar [textSizeRatio]="2.5" size="32" [user]="feedback?.estimator"></tpt-avatar>
      <span class="name">
        {{feedback?.estimator?.firstName}} {{feedback?.estimator?.lastName}}
      </span>
    </div>
    <div class="post-date" *ngIf="feedback?.created">{{feedback?.created | localDate: 'dd.MM.yyyy'}}</div>
  </div>
</div>
